import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { Device } from "./DeviceModel";

export const getDeviceDirectory = (prjId: Number, params: {}): Promise<Directory[]> => {
    return new Promise<Directory[]>((resolve) => {
        resolve(agentInstance(prjId).get(`devices/dir/`));
    });
};

export const getDeviceList = (prjId: Number, params: {}): Promise<Device[]> => {
    return new Promise<Device[]>((resolve) => {
        resolve(agentInstance(prjId).get(`devices/`, {
            params: params["filter"],
        }));
    });
};

export const apiDeviceBlock = (prjId: Number, params: {}): Promise<Device[]> => {
    return new Promise<Device[]>((resolve) => {
        resolve(agentInstance(prjId).patch(`devices/` + params["id"] + `/block/`));
    });
};
export const apiDeviceUnblock = (prjId: Number, params: {}): Promise<Device[]> => {
    return new Promise<Device[]>((resolve) => {
        resolve(agentInstance(prjId).patch(`devices/` + params["id"] + `/unblock/`));
    });
};