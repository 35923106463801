import * as types from "./TemplateTypes";

import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Template, TemplateFilter } from "./TemplateModel";

export type TemplateState = {
  list: {
    loading: boolean;
    items: Template[];
    pagination: Pagination;
  };
  creating: boolean;
  updating: boolean;
  directory: {
    loading: boolean;
    items: Directory[];
  };
  filter: TemplateFilter;
};

const defaultTemplatePagination = {
  CurrentPage: 0,
  PageCount: 0,
  per_page: 25,
  TotalCount: 0,
};

export const defaultTemplateState: TemplateState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultTemplatePagination,
  },
  creating: false,
  updating: false,
  directory: {
    loading: false,
    items: [],
  },
  filter: {
    page: 0,
    per_page: defaultTemplatePagination.per_page,
    order_by: "out_version",
    order: "asc",
  },
};

export const templateReducer = (
  state: TemplateState = defaultTemplateState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_TEMPLATE_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_TEMPLATE_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_TEMPLATE_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultTemplatePagination,
        },
      };

    // ***

    case types.GET_TEMPLATE_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_TEMPLATE_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_TEMPLATE_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };

    // ***

    case types.CREATE_TEMPLATE_START:
      return {
        ...state,
        creating: true,
      };

    case types.CREATE_TEMPLATE_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.CREATE_TEMPLATE_FAILED:
      return {
        ...state,
        creating: false,
      };

    // ***

    case types.UPDATE_TEMPLATE_START:
      return {
        ...state,
        creating: true,
      };

    case types.UPDATE_TEMPLATE_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.UPDATE_TEMPLATE_FAILED:
      return {
        ...state,
        creating: false,
      };
  }

  return state;
};
