import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { userCreateRequestStartAction } from "../../store/User/UserAction";
import { UserCreateForm } from "../../store/User/UserModel";
import { UserCreateComponent } from "./create.component";

const mapStateToProps = (state: State) => ({
  userCreating: state.user.creating,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestUserCreate: (form: UserCreateForm) =>
    dispatch(userCreateRequestStartAction(form)),
});

export const UserCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserCreateComponent);
