import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { TemplateUpdateForm } from "../../store/Template/TemplateModel";

interface Props {
  templateUpdating: boolean;
  onRequestTemplateUpdate: (id: number, form: TemplateUpdateForm) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const TemplateUpdateComponent = (props: any) => {
  const classes = useStyles();
  const { template, handleClose, templateUpdating, onRequestTemplateUpdate } =
    props;

  const updateForm = {
    id: template.id,
    priority: template.priority,
    ttl: template.ttl,
    name: template.name,
    comment: template.comment,
    fields: JSON.stringify(template.fields, null, "\t"),
  };

  const [fields, setFields] = React.useState<TemplateUpdateForm>(updateForm);

  const handleChangeField =
    (prop: keyof TemplateUpdateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  const handleChangeNumberField =
    (prop: keyof TemplateUpdateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: Number(event.target.value) });
    };

  // ***

  const handleStartUpdate = () => {
    onRequestTemplateUpdate(template.id, fields);
    handleClose();
  };

  return (
    <>
      <DialogTitle>
        Редактирование шаблона <b>"{template.name}"</b>
      </DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.name}
                label="Название"
                onChange={handleChangeField("name")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.comment}
                label="Комментарий"
                onChange={handleChangeField("comment")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.priority}
                label="Приоритет"
                type="number"
                onChange={handleChangeNumberField("priority")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.ttl}
                label="Время жизни (в днях)"
                type="number"
                onChange={handleChangeNumberField("ttl")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.fields}
                label="Схема"
                multiline
                minRows={10}
                maxRows={10}
                onChange={handleChangeField("fields")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!templateUpdating && (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartUpdate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </>
        )}

        {templateUpdating && <CircularProgress />}
      </DialogActions>
    </>
  );
};
