export const deviceStatusTitle = {
    0: "Контроллер еще не присылал пингов",
    1: "Контроллер умер",
    2: "Не все пинги получены, и получено менее 75%",
    3: "Не все пинги получены, но получено более 75%",
    4: "??",
    5: "Все чётенько",
};

export const firmwareTypeTitle = {
    "bvv": "БВВ",
    "ppo": "ППО",
    "os": "ОС",
};
