import { useCallback, useEffect, useState } from "react";
import { useHistory, useLocation } from "react-router";

import { default as qs } from "query-string";

const useFilter = (filter, onFilterChange) => {
  const history = useHistory();
  const location = useLocation();

  const [newFilter, setNewFilter] = useState({ ...filter, ...qs.parse(location.search) });
  // const newFilter = { ...filter, ...qs.parse(location.search) };

  useEffect(() => {
    // console.log("newFilter", newFilter)
    changeFilter(newFilter, false);
  }, []);

  const changeFilter = (newFilter, isChanged: Boolean) => {
    // console.log("changeFilter / pre", newFilter, isChanged)
    
    if (isChanged === true) {
      setNewFilter({ ...qs.parse(location.search), ...newFilter })
      // newFilter = { ...qs.parse(location.search), ...newFilter };
    } else {
      setNewFilter({ ...newFilter, ...qs.parse(location.search) })
      // newFilter = { ...newFilter, ...qs.parse(location.search) };
    }

    // console.log("changeFilter / merge", newFilter, isChanged)
    
    history.replace({
      pathname: location.pathname,
      search: qs.stringify(newFilter),
    });

    // console.log("changeFilter / history", location.search)
    onFilterChange(newFilter);
  };

  const handleChangeOrder = useCallback((property: string) => {
    if (newFilter.order_by !== property) {
      newFilter.order = "asc";
      newFilter.order_by = property;
    } else {
      newFilter.order = newFilter.order === "asc" ? "desc" : "asc";
    }

    changeFilter(newFilter, true);
  }, [newFilter, changeFilter]);

  const handleChangeValue = useCallback((changes: Object) => {
    const changedFilter = { ...newFilter, ...changes }
    // console.log("handleChangeValue / pre", changes)
    // console.log("handleChangeValue / newFilter", newFilter)
    // console.log("handleChangeValue / changedFilter", changedFilter)

    changeFilter(changedFilter, true);
  }, [newFilter, changeFilter]);

  return [newFilter, handleChangeOrder, handleChangeValue];
};

export default useFilter;
