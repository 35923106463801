import * as projectTypes from "../Project/ProjectTypes";
import * as types from "./EventTypes";

import { BaseAction, Pagination } from "../Common/CommonModel";
import { Event, EventFilter } from "./EventModel";

import { rfc3339 } from "../../utils";

export type EventState = {
  list: {
    loading: boolean;
    items: Event[];
    pagination: Pagination;
  };
  filter: EventFilter;
  detailed: {
    loading: boolean;
    event: Event;
  };
};

const defaultEventPagination = {
  CurrentPage: 0,
  PageCount: 0,
  per_page: 50,
  TotalCount: 0,
};

export const defaultEventState: EventState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultEventPagination,
  },
  filter: {
    page: 0,
    per_page: defaultEventPagination.per_page,
    order_by: "id",
    order: "desc",
    device_id: -1,
    out_version: 0,
    min_event_at: rfc3339(new Date(), true),
    max_event_at: rfc3339(new Date(), true),
  },
  detailed: {
    loading: false,
    event: null,
  },
};

export const eventReducer = (
  state: EventState = defaultEventState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_EVENT_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_EVENT_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_EVENT_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultEventPagination,
        },
      };

    case types.GET_EVENT_ITEM_START:
      return {
        ...state,
        detailed: {
          loading: true,
          event: {
            id: action.payload.id,
          },
        },
      };

    case types.GET_EVENT_ITEM_COMPLETED:
      return {
        ...state,
        detailed: {
          loading: false,
          event: action.payload,
        },
      };

    case projectTypes.SELECT_PROJECT:
      return {
        ...state,
        filter: {
          ...state.filter,
          device_id: -1,
          out_version: 0,
        },
      };
  }

  return state;
};
