import * as types from "./DeviceTypes";

import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Device, DeviceFilter } from "./DeviceModel";

export type DeviceState = {
  list: {
    loading: boolean;
    items: Device[];
    pagination: Pagination;
  };
  directory: {
    loading: boolean;
    items: Directory[];
  };
  filter: DeviceFilter;
};

const defaultDevicePagination = {
  CurrentPage: 0,
  PageCount: 0,
  per_page: 25,
  TotalCount: 0,
};

export const defaultDeviceState: DeviceState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultDevicePagination,
  },
  directory: {
    loading: false,
    items: [],
  },
  filter: {
    page: 0,
    per_page: defaultDevicePagination.per_page,
    order_by: "id",
    order: "asc",
    show_blocked: false,
  },
};

export const deviceReducer = (
  state: DeviceState = defaultDeviceState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_DEVICE_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_DEVICE_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_DEVICE_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultDevicePagination,
        },
      };

    // ***

    case types.GET_DEVICE_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_DEVICE_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_DEVICE_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };
  }

  return state;
};
