import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { userUpdateRequestStartAction } from "../../store/User/UserAction";
import { UserUpdateForm } from "../../store/User/UserModel";
import { UserUpdateComponent } from "./update.component";

const mapStateToProps = (state: State) => ({
  userUpdating: state.user.updating,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestUserUpdate: (id: number, form: UserUpdateForm) =>
    dispatch(userUpdateRequestStartAction(id, form)),
});

export const UserUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(UserUpdateComponent);
