import { connect } from "react-redux";
import { deviceDirectoryRequestStartAction } from "../../store/Device/DeviceAction";
import {
  eventItemRequestStartAction,
  eventListRequestStartAction,
} from "../../store/Event/EventAction";
import { EventFilter } from "../../store/Event/EventModel";
import { State } from "../../store/reducers";
import { templateDirectoryRequestStartAction } from "../../store/Template/TemplateAction";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  eventsLoading: state.event.list.loading,
  events: state.event.list.items,
  pagination: state.event.list.pagination,
  filter: state.event.filter,
  detailedLoading: state.event.detailed.loading,
  detailedEvent: state.event.detailed.event,
  settings: state.settings,
  deviceDirectory: state.device.directory.items,
  templateDirectory: state.template.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestEventList: (filter: EventFilter) =>
    dispatch(eventListRequestStartAction(filter)),
  onRequestEventItem: (id: number) => dispatch(eventItemRequestStartAction(id)),
  onRequestDeviceDirectory: () => dispatch(deviceDirectoryRequestStartAction()),
  onRequestTemplateDirectory: () =>
    dispatch(templateDirectoryRequestStartAction()),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
