import { BaseAction, Directory } from "../Common/CommonModel";
import { Project, ProjectFilter } from "./ProjectModel";
import * as types from "./ProjectTypes";

export type ProjectState = {
  list: {
    loading: boolean;
    items: Project[];
  };
  directory: {
    loading: boolean;
    items: Directory[];
  };
  filter: ProjectFilter;
  selected: Directory;
};

export const defaultProjectState: ProjectState = {
  list: {
    loading: false,
    items: [],
  },
  directory: {
    loading: false,
    items: [],
  },
  filter: {
    order_by: "id",
    order: "asc",
  },
  selected: {
    id: null,
    title: "",
  },
};

export const projectReducer = (
  state: ProjectState = defaultProjectState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.SELECT_PROJECT:
      return {
        ...state,
        selected: action.payload.prj,
      };

    case types.GET_PROJECT_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_PROJECT_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload,
        },
      };

    case types.GET_PROJECT_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
        },
      };

    case types.GET_PROJECT_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_PROJECT_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_PROJECT_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };
  }

  return state;
};
