import {
  Card,
  CardActions,
  CardContent,
  Divider,
  Grid,
  GridList,
  GridListTile,
  IconButton,
  List,
  ListItem,
  ListItemSecondaryAction,
  ListItemText,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import DoneIcon from "@material-ui/icons/Done";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import GetAppIcon from "@material-ui/icons/GetApp";
import OpenInNewIcon from "@material-ui/icons/OpenInNew";
import RotateLeftIcon from "@material-ui/icons/RotateLeft";
import SearchIcon from "@material-ui/icons/Search";
import React, { useEffect } from "react";
import DateTime from "../../components/formatters/date-time";
import { Pagination } from "../../store/Common/CommonModel";
import {
  File,
  FileFilter,
  FileRequest,
  FileRequestFilter,
} from "../../store/File/FileModel";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  onCreateFileRequestAction: (deviceId: number, mask: string) => void;
  onRequestFileRequestItem: (filter: FileRequestFilter) => void;
  onFilesRequestList: (filter: FileFilter) => void;
  onDownloadFileAction: (deviceId: number, fileId: number) => void;
  fileRequestLoading: boolean;
  fileRequestPagination: Pagination;
  fileRequestFilter: FileRequestFilter;
  fileRequests: FileRequest[];
  fileLoading: boolean;
  filePagination: Pagination;
  fileFilter: FileFilter;
  files: File[];
  settings: Settings;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      //   height: 216,
      flexGrow: 1,
      // maxWidth: 400,
    },
    divider: {
      marginLeft: -1, //theme.spacing(0, -1),
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    filesList: {
      paddingLeft: 10,
    },
    fileItem: {
      padding: "15px 0 0 15px !important",
    },
    fileContent: {
      padding: 15, //"15px 12px 0 12px",
    },
    fileActions: {
      padding: 0,
    },
    fileDivider: {
      margin: 0, //"12px 0 0 0",
    },

    glroot: {
      display: "flex",
      flexWrap: "wrap",
      justifyContent: "space-around",
      overflow: "hidden",
      backgroundColor: theme.palette.background.paper,
    },
    gridList: {
      width: "100%",
      //   height: 450,
    },

    listRoot: {
      width: "100%",
      // maxWidth: 360,
      backgroundColor: theme.palette.background.paper,
    },
    inline: {
      display: "inline",
    },
    icon: {
      color: "rgba(255, 255, 255, 0.54)",
    },
    listForm: {
      paddingRight: 8,
      paddingTop: 0,
      display: "flex",
      alignItems: "center",
      width: "100%",
    },
  })
);

export const FileManagerComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    fileRequests,
    fileRequestFilter,
    fileFilter,
    files,
    settings,
    fileLoading,
    onCreateFileRequestAction,
    onDownloadFileAction,
    onRequestFileRequestItem,
    onFilesRequestList,
  } = props;

  const [selectedIndex, setSelectedIndex] = React.useState(0);
  const [mask, setMask] = React.useState<string>("");

  const handleListItemClick = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>,
    index: number
  ) => {
    setSelectedIndex(index);
    fileFilter.request_id = index;
    onFilesRequestList(fileFilter);
  };

  const handleMaskRequestChange = (
    event: React.ChangeEvent<{ value: string }>
  ) => {
    setMask(event.target.value);
  };

  const handleCreateRequestClick = () => {
    if (!mask) {
      alert("Validation Error: `Маска`");
      return;
    }

    onCreateFileRequestAction(fileRequestFilter.device_id, mask);
    setMask("");
  };

  const handleDownloadFileClick = (fileId: number) => {
    onDownloadFileAction(fileRequestFilter.device_id, fileId);
  };

  const handleOpenFileClick = () => {
    // onCreateFileRequestAction(fileRequestFilter.device_id, mask);
  };

  useEffect(() => {
    onRequestFileRequestItem(fileRequestFilter);

    const interval = setInterval(() => {
      onRequestFileRequestItem(fileRequestFilter);
      if (0 < fileFilter.request_id) onFilesRequestList(fileFilter);
    }, 5000);

    return () => clearInterval(interval);
  }, []);

  return (
    <Grid container className={classes.root} spacing={0}>
      <Grid item xs={12} sm={4}>
        <List component="nav">
          <ListItem className={classes.listForm}>
            <TextField
              id="standard-basic"
              fullWidth
              label="Маска"
              value={mask}
              onChange={handleMaskRequestChange}
            />
            <IconButton
              type="submit"
              aria-label="search"
              onClick={handleCreateRequestClick}
            >
              <SearchIcon />
            </IconButton>
          </ListItem>

          <Divider />

          {fileRequests &&
            0 < fileRequests.length &&
            fileRequests.map((item) => (
              <ListItem
                button
                selected={selectedIndex === item.id}
                onClick={(event) => handleListItemClick(event, item.id)}
                disabled={item.status != 2}
                key={item.id}
              >
                <ListItemText
                  primary={"Маска: " + item.mask}
                  secondary={
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.created_at}
                    />
                  }
                />

                <ListItemSecondaryAction>
                  {item.status === 0 && <ErrorOutlineIcon />}
                  {item.status === 1 && <RotateLeftIcon />}
                  {item.status === 2 && <DoneIcon />}
                </ListItemSecondaryAction>
              </ListItem>
            ))}

          {(!fileRequests || (fileRequests && fileRequests.length === 0)) && (
            <ListItem>Запросы отсутствуют</ListItem>
          )}
        </List>
      </Grid>

      <Divider orientation="vertical" flexItem className={classes.divider} />

      <Grid item xs={12} sm={8}>
        <GridList cols={3} cellHeight={"auto"} className={classes.filesList}>
          {files &&
            0 < files.length &&
            files.map((item) => (
              <GridListTile className={classes.fileItem}>
                <Card variant="outlined">
                  <CardContent className={classes.fileContent}>
                    <div>
                      <b>{item.file_name}</b> <small>({item.file_size}Б)</small>
                    </div>
                    <div>
                      <small>
                        <DateTime
                          dateFormat={settings.dateFormat}
                          timeFormat={settings.timeFormat}
                          timeZone={settings.timeZone}
                          dateTime={item.created_at}
                        />
                      </small>
                    </div>
                  </CardContent>
                  <Divider className={classes.fileDivider} />
                  <CardActions className={classes.fileActions} disableSpacing>
                    {item.file_size === 0 && (
                      <IconButton disabled>
                        <GetAppIcon />
                      </IconButton>
                    )}

                    {item.status == 0 && 0 < item.file_size && (
                      <IconButton
                        onClick={() => handleDownloadFileClick(item.id)}
                      >
                        <GetAppIcon />
                      </IconButton>
                    )}

                    {item.status == 1 && (
                      <IconButton disabled>
                        <RotateLeftIcon />
                      </IconButton>
                    )}

                    {item.status == 2 && (
                      <IconButton
                        href={item.url}
                        target="_blank"
                        rel="noreferrer"
                      >
                        <OpenInNewIcon />
                      </IconButton>
                    )}
                  </CardActions>
                </Card>
              </GridListTile>
            ))}

          {(!files || (files && files.length === 0)) && (
            <p>Файлы отсутствуют</p>
          )}
        </GridList>
      </Grid>

      {/* <Backdrop className={classes.backdrop} open={fileLoading}>
        <CircularProgress color="inherit" />
      </Backdrop> */}
    </Grid>
  );
};
