import {
  Button,
  Checkbox,
  Chip,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  FormControlLabel,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React, { useEffect } from "react";
import { firmwareTypeTitle } from "../../store/Common/CommonConstant";
import { Directory } from "../../store/Common/CommonModel";
import { Firmware } from "../../store/Firmware/FirmwareModel";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  onRequestDeviceDirectory: () => void;
  onRequestFirmwareDirectory: () => void;
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) => void;
  deviceDirectory: Directory[];
  deviceDirLoading: boolean;
  firmwareDirectory: Firmware[];
  firmwareDirLoading: boolean;
  firmwareFlashing: boolean;
  settings: Settings;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const FirmwareFlashForm = (props: any) => {
  const classes = useStyles();
  const {
    firmware,
    device,
    deviceDirectory,
    deviceDirLoading,
    firmwareDirectory,
    firmwareDirLoading,
    firmwareFlashing,
    onRequestDeviceDirectory,
    onRequestFirmwareDirectory,
    onRequestFirmwareFlash,
    handleCloseFlash,
  } = props;

  useEffect(() => {
    onRequestDeviceDirectory();
    onRequestFirmwareDirectory();
  }, []);

  const [formFirmware, setFormFirmware] = React.useState<Directory>(null);
  const [formDevices, setFormDevices] = React.useState<Directory[]>([]);

  if (firmware && formFirmware == null && 0 < firmwareDirectory.length) {
    setFormFirmware({
      id: firmware.id,
      title:
        "#" + firmware.id + " - " + firmware.f_type + "." + firmware.f_version,
      group: firmware.type,
    });
  }

  if (device && formDevices.length === 0 && 0 < deviceDirectory.length) {
    let currDirectories = formDevices;
    currDirectories.push({
      id: device.id,
      title: "",
      group: device.status,
    });
    setFormDevices(currDirectories);
  }

  const [modbus, setModbus] = React.useState<number>();
  const [sendCommand, setSendCommand] = React.useState<boolean>(true);

  const handleChangeFirmware = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    firmware: Directory
  ) => {
    setFormFirmware(firmware);
  };

  const handleChangeDevices = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    devices: Directory[]
  ) => {
    setFormDevices(devices);
  };

  const handleChangeSendCommand = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    setSendCommand(event.target.checked);
  };

  const handleChangeModbus = (event: React.ChangeEvent<HTMLInputElement>) => {
    setModbus(Number(event.target.value));
  };

  const handleStartFlash = () => {
    let devices = [];

    formDevices.forEach(function (item, i, arr) {
      devices.push(item.id);
    });

    if (formFirmware == null || formFirmware.id < 1) {
      alert("Validation Error: `Прошивка`");
      return;
    }
    if (devices.length < 1) {
      alert("Validation Error: `Контроллеры`");
      return;
    }
    if (formFirmware.group === "bvv" && (modbus < 0 || 255 < modbus)) {
      alert("Validation Error: `Адрес Modbus БВВ`");
      return;
    }

    onRequestFirmwareFlash(formFirmware.id, devices as [], sendCommand, modbus);

    handleCloseFlash();
  };

  return (
    <>
      <DialogTitle id="customized-dialog-title">
        Прошить контроллеры
      </DialogTitle>
      <DialogContent dividers>
        <FormControl className={classes.formControl}>
          <Autocomplete
            id="firmware-box"
            options={firmwareDirectory}
            getOptionLabel={(option: Directory) => option.title}
            getOptionSelected={(option: Directory, value: Directory) => {
              return option.id === value.id;
            }}
            groupBy={(option) => firmwareTypeTitle[option.group]}
            disabled={firmwareDirLoading}
            value={formFirmware}
            onChange={handleChangeFirmware}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Прошивки"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {firmwareDirLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <Autocomplete
            id="device-box"
            options={deviceDirectory}
            getOptionLabel={(option: Directory) =>
              "#" + option.id + " - " + option.title
            }
            getOptionSelected={(option: Directory, value: Directory) => {
              return option.id === value.id;
            }}
            // groupBy={(option) => deviceStatusTitle[option.group]}
            disabled={deviceDirLoading}
            limitTags={2}
            value={formDevices}
            onChange={handleChangeDevices}
            multiple
            renderInput={(params) => (
              <TextField
                {...params}
                label="Контроллеры"
                variant="standard"
                placeholder="Favorites"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {deviceDirLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
            renderTags={(selected) => (
              <div className={classes.chips}>
                {selected.map((value) => (
                  <Chip
                    key={value.id}
                    label={"#" + value.id}
                    className={classes.chip}
                  />
                ))}
              </div>
            )}
          />
        </FormControl>

        {firmware != null && firmware.type === "bvv" && (
          <FormControl className={classes.formControl}>
            <TextField
              label="Адрес Modbus БВВ"
              value={modbus}
              onChange={handleChangeModbus}
            />
          </FormControl>
        )}

        <FormControl className={classes.formControl}>
          <FormControlLabel
            control={
              <Checkbox
                checked={sendCommand}
                onChange={handleChangeSendCommand}
                color="primary"
              />
            }
            label="Немедленно отправить команду на обновление (`0x10800000`, `0x14800000` или `0x28800000` в зависимости от типа прошивки)"
          />
        </FormControl>
      </DialogContent>
      <DialogActions>
        {!firmwareFlashing && (
          <>
            <Button onClick={handleCloseFlash} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartFlash}
              variant="contained"
              color="primary"
            >
              Flash
            </Button>
          </>
        )}

        {firmwareFlashing && <CircularProgress />}
      </DialogActions>
    </>
  );
};
