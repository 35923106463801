import * as types from './FileTypes';

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
    createFileRequestCompletedAction,
    createFileRequestFailedAction,
    downloadFileCompletedAction,
    downloadFileFailedAction,
    fileRequestCompletedAction,
    fileRequestFailedAction,
    fileRequestStartAction,
    filesCompletedAction,
    filesFailedAction
} from "./FileAction";
import { getFileList, getFileRequestList, postCreateFileRequest, postDownloadFile } from "./FileApis";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

const getFilter = (state: State) => state.file.filterRequest;
const getProject = (state: State) => state.project.selected.id;

export function* watchFileRequestStart() {
    yield takeEvery(types.GET_FILE_REQUEST_LIST_START, getFileRequestsList);
    yield takeEvery(types.GET_FILE_LIST_START, getFilesList);
    yield takeEvery(types.CREATE_FILE_REQUEST_START, createFileRequests);
    yield takeEvery(types.FILE_DOWNLOAD_START, downloadFileRequests);
}

function* getFileRequestsList(action: BaseAction) {
    try {
        const prjId = yield select(getProject)
        const { resp } = yield all({
            resp: call(getFileRequestList, prjId, action.payload),
        });

        if (resp.status !== 200) {
            throw new Error();
        }

        yield put(fileRequestCompletedAction(resp.data, {
            CurrentPage: parseInt(
                resp.headers["x-pagination-current-page"]
            ),
            PageCount: parseInt(resp.headers["x-pagination-page-count"]),
            per_page: parseInt(resp.headers["x-pagination-per-page"]),
            TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
        }));
    } catch (e) {
        yield put(fileRequestFailedAction());

        const errorNotification = creatingNotification(
            "Не удалось получить список запросов",
            "error"
        );
        yield put(enqueueSnackbar(errorNotification));
    }
}

function* getFilesList(action: BaseAction) {
    try {
        const prjId = yield select(getProject)
        const { resp } = yield all({
            resp: call(getFileList, prjId, action.payload),
        });

        if (resp.status !== 200) {
            throw new Error();
        }

        yield put(filesCompletedAction(resp.data, {
            CurrentPage: parseInt(
                resp.headers["x-pagination-current-page"]
            ),
            PageCount: parseInt(resp.headers["x-pagination-page-count"]),
            per_page: parseInt(resp.headers["x-pagination-per-page"]),
            TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
        }));
    } catch (e) {
        yield put(filesFailedAction());

        const errorNotification = creatingNotification(
            "Не удалось получить список файлов",
            "error"
        );
        yield put(enqueueSnackbar(errorNotification));
    }
}

function* createFileRequests(action: BaseAction) {
    try {
        const prjId = yield select(getProject)
        const { resp } = yield all({
            resp: call(postCreateFileRequest, prjId, action.payload),
        });

        if (resp.status !== 202) {
            throw new Error();
        }

        yield put(downloadFileCompletedAction());
    } catch (e) {
        yield put(createFileRequestFailedAction());

        const errorNotification = creatingNotification(
            "Не удалось создать запрос",
            "error"
        );
        yield put(enqueueSnackbar(errorNotification));
    }

    const filter = yield select(getFilter);
    yield put(fileRequestStartAction(filter));
}

function* downloadFileRequests(action: BaseAction) {
    try {
        const prjId = yield select(getProject)
        const { resp } = yield all({
            resp: call(postDownloadFile, prjId, action.payload),
        });

        if (resp.status !== 202) {
            throw new Error();
        }

        yield put(createFileRequestCompletedAction(""));
    } catch (e) {
        yield put(downloadFileFailedAction());

        const errorNotification = creatingNotification(
            "Не удалось получить файл",
            "error"
        );
        yield put(enqueueSnackbar(errorNotification));
    }
}
