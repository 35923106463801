import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import {
  Template,
  TemplateCreateForm,
  TemplateFilter,
  TemplateUpdateForm,
} from "./TemplateModel";
import * as types from "./TemplateTypes";

export const templatesRequestStartAction = (
  filter: TemplateFilter
): BaseAction => ({
  type: types.GET_TEMPLATE_LIST_START,
  payload: {
    filter: filter,
  },
});

export const templatesRequestCompletedAction = (
  list: Template[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_TEMPLATE_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const templatesRequestFailedAction = (): BaseAction => ({
  type: types.GET_TEMPLATE_LIST_FAILED,
  payload: {},
});

// ***

export const templateDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_TEMPLATE_DIRECTORY_START,
  payload: {},
});

export const templateDirectoryRequestCompletedAction = (
  list: Directory[]
): BaseAction => ({
  type: types.GET_TEMPLATE_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const templateDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_TEMPLATE_DIRECTORY_FAILED,
  payload: {},
});

// ***

export const templateCreateRequestStartAction = (
  form: TemplateCreateForm
): BaseAction => ({
  type: types.CREATE_TEMPLATE_START,
  payload: {
    form: form,
  },
});

export const templateCreateRequestCompletedAction = (): BaseAction => ({
  type: types.CREATE_TEMPLATE_COMPLETED,
  payload: {},
});

export const templateCreateRequestFailedAction = (): BaseAction => ({
  type: types.CREATE_TEMPLATE_FAILED,
  payload: {},
});

// ***

export const templateUpdateRequestStartAction = (
  id: number,
  form: TemplateUpdateForm
): BaseAction => ({
  type: types.UPDATE_TEMPLATE_START,
  payload: {
    id: id,
    form: form,
  },
});

export const templateUpdateRequestCompletedAction = (): BaseAction => ({
  type: types.UPDATE_TEMPLATE_COMPLETED,
  payload: {},
});

export const templateUpdateRequestFailedAction = (): BaseAction => ({
  type: types.UPDATE_TEMPLATE_FAILED,
  payload: {},
});
