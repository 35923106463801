import { connect } from "react-redux";
import { deviceDirectoryRequestStartAction } from "../../store/Device/DeviceAction";
import {
  firmwareDirectoryRequestStartAction,
  firmwareFlashStartAction,
} from "../../store/Firmware/FirmwareAction";
import { State } from "../../store/reducers";
import { FirmwareFlashForm } from "./flash.component";

const mapStateToProps = (state: State) => ({
  deviceDirectory: state.device.directory.items,
  deviceDirLoading: state.device.directory.loading,
  firmwareDirectory: state.firmware.directory.items,
  firmwareDirLoading: state.firmware.directory.loading,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceDirectory: () => dispatch(deviceDirectoryRequestStartAction()),
  onRequestFirmwareDirectory: () =>
    dispatch(firmwareDirectoryRequestStartAction()),
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) =>
    dispatch(
      firmwareFlashStartAction(firmwareId, devices, sendCommand, modbus)
    ),
});

export const FirmwareFlashContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FirmwareFlashForm);
