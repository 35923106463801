import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { EventDetailsComponent } from "./details.component";

const mapStateToProps = (state: State) => ({
  detailedEvent: state.event.detailed.event,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({});

export const EventDetailsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(EventDetailsComponent);
