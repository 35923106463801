export const GET_FILE_REQUEST_LIST_ERASE = "GET_FILE_REQUEST_LIST_ERASE - Erase"

export const GET_FILE_REQUEST_LIST_START = "GET_FILE_REQUEST_LIST - Start";
export const GET_FILE_REQUEST_LIST_COMPLETED = "GET_FILE_REQUEST_LIST - Completed";
export const GET_FILE_REQUEST_LIST_FAILED = "GET_FILE_REQUEST_LIST - Failed";

export const GET_FILE_LIST_START = "GET_FILE_LIST - Start";
export const GET_FILE_LIST_COMPLETED = "GET_FILE_LIST - Completed";
export const GET_FILE_LIST_FAILED = "GET_FILE_LIST - Failed";

export const CREATE_FILE_REQUEST_START = "CREATE_FILE_REQUEST - Start";
export const CREATE_FILE_REQUEST_COMPLETED = "CREATE_FILE_REQUEST - Completed";
export const CREATE_FILE_REQUEST_FAILED = "CREATE_FILE_REQUEST - Failed";

export const FILE_DOWNLOAD_START = "FILE_DOWNLOAD - Start";
export const FILE_DOWNLOAD_FAILED = "FILE_DOWNLOAD - Completed";
export const FILE_DOWNLOAD_COMPLETED = "FILE_DOWNLOAD - Failed";
