import Notifier from "../components/notifier";
import React from "react";

const EmptyLayout = (props: any) => {
  return (
    <>
      <Notifier />
      {props.children}
    </>
  );
};

export default EmptyLayout;
