import * as types from './CommandTypes';

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  runCommandCompletedAction,
  runCommandFailedAction,
} from "./CommandAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { apiRunCommand } from "./CommandApis";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchRunCommandStart() {
  yield takeEvery(types.RUN_COMMAND_START, runCommand);
}

const getProject = (state: State) => state.project.selected.id;

function* runCommand(action: BaseAction) {
  try {
      const prjId = yield select(getProject)
      const { resp } = yield all({
          resp: call(apiRunCommand, prjId, action.payload),
      });
      
      if (resp.status !== 202) {
          throw new Error();
      }
      
      yield put(runCommandCompletedAction(resp));
  } catch (e) {
      yield put(runCommandFailedAction());

      const errorNotification = creatingNotification(
          "При отправке команды произошла ошибка",
          "error"
      );
      yield put(enqueueSnackbar(errorNotification));
  }
}
