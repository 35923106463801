import axios from "axios";
import { API_BASE } from '../constants/env';
import { store } from '../store';
import * as commontypes from '../store/Common/CommonTypes';

const ClientId = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoxLCJ2leHAiOjE2MTk4NTExMTN9.dZy2S7Xhujm4bpTcSjqaa4hIc_OuI5Vn5TJzXGzrPKo";

var client = axios.create({
    baseURL: API_BASE,
    timeout: 30000,
    headers: {
        "Content-Type": "application/json",
        "Cache-Control": "no-cache",
    },
});

const agentInstance = (prjId: Number) => {
    if (store.getState().auth.isAuthenticated) {
        client.defaults.headers.common['Authorization'] = "Bearer " + store.getState().auth.accessToken;
    }

    client.defaults.headers.common['Accept-Poject'] = store.getState().project.selected.id;
    
    return client;
}

client.interceptors.response.use(response => {
    return response;
}, error => {
    if (error.response.status === 401) {
        store.dispatch({
            type: commontypes.API_ERROR_401,
            payload: error,
        })
        return Promise.reject(error);
    }
    return error;
});

export default agentInstance;
