import { AuthState, authReducer } from "./Auth/AuthReducer";
import { CommandState, commandReducer } from "./Command/CommandReducer";
import { DeviceState, deviceReducer } from "./Device/DeviceReducer";
import { EventState, eventReducer } from "./Event/EventReducer";
import { FileState, fileReducer } from "./File/FileReducer";
import {
    FirmwareState,
    firmwareReducer
} from "./Firmware/FirmwareReducer";
import { NavState, navReducer } from "./Nav/NavReducer";
import { ProjectState, projectReducer } from "./Project/ProjectReducer";
import { SettingsState, settingsReducer } from "./Settings/SettingsReducer";
import {
    SnackbarState,
    snackbarReducer
} from "./Snackbar/SnackbarReducer";
import {
    TemplateState,
    templateReducer
} from "./Template/TemplateReducer";
import {
    UserState,
    userReducer
} from "./User/UserReducer";

import { combineReducers } from "redux";

export interface State {
  auth: AuthState;
  command: CommandState;
  nav: NavState;
  device: DeviceState;
  event: EventState;
  project: ProjectState;
  settings: SettingsState;
  snackbar: SnackbarState;
  template: TemplateState;
  firmware: FirmwareState;
  file: FileState;
  user: UserState;
}

export const rootReducer = combineReducers<State>({
  auth: authReducer,
  command: commandReducer,
  nav: navReducer,
  device: deviceReducer,
  event: eventReducer,
  project: projectReducer,
  settings: settingsReducer,
  snackbar: snackbarReducer,
  template: templateReducer,
  firmware: firmwareReducer,
  file: fileReducer,
  user: userReducer,
});
