import {
  Divider,
  Drawer,
  IconButton,
  ListItemIcon,
  MenuItem,
  MenuList,
  Typography,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";

import ChevronLeftIcon from "@material-ui/icons/ChevronLeft";
import ChevronRightIcon from "@material-ui/icons/ChevronRight";
import CompareArrowsIcon from "@material-ui/icons/CompareArrows";
import DirectionsIcon from "@material-ui/icons/Directions";
import { Directory } from "../../store/Common/CommonModel";
import FolderIcon from "@material-ui/icons/Folder";
import HomeIcon from "@material-ui/icons/Home";
import { Link } from "react-router-dom";
import MemoryIcon from "@material-ui/icons/Memory";
import { NavState } from "../../store/Nav/NavReducer";
import PeopleAltIcon from "@material-ui/icons/PeopleAlt";
import PowerInputIcon from "@material-ui/icons/PowerInput";
import PublishIcon from "@material-ui/icons/Publish";
import React from "react";
import SettingsIcon from "@material-ui/icons/Settings";

interface Props {
  onToggleNav: (isOpen: boolean) => void;
  onChangePath: (path: string) => void;
  nav: NavState;
  selectedProject: Directory;
  isAdmin: boolean;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    drawer: {
      width: drawerWidth,
      flexShrink: 0,
    },
    drawerPaper: {
      width: drawerWidth,
    },
    drawerHeader: {
      display: "flex",
      alignItems: "center",
      padding: theme.spacing(0, 1),
      ...theme.mixins.toolbar,
      justifyContent: "flex-end",
    },
    navs: {
      display: "flex",
      flexDirection: "column",
      justifyContent: "space-between",
      height: "100%",
    },
    menuItem: {
      // paddingLeft: 24,
      // paddingRight: 20,
    },
    menuIcon: {
      minWidth: 40,
    },
    divider: {
      marginBottom: 5,
      marginTop: 5,
    },
  })
);

export const NavComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const theme = useTheme();

  const { onChangePath, onToggleNav, nav, selectedProject, isAdmin } = props;

  return (
    <Drawer
      className={classes.drawer}
      classes={{
        paper: classes.drawerPaper,
      }}
      variant="temporary"
      open={nav.isOpen}
      onClose={() => onToggleNav(false)}
    >
      <div className={classes.drawerHeader}>
        <IconButton onClick={() => onToggleNav(false)}>
          {theme.direction === "ltr" ? (
            <ChevronLeftIcon />
          ) : (
            <ChevronRightIcon />
          )}
        </IconButton>
      </div>

      <Divider />

      <div className={classes.navs}>
        <MenuList onClick={() => onToggleNav(false)}>
          <MenuItem
            component={Link}
            to="/"
            className={classes.menuItem}
            selected={nav.pagePath === "/"}
            onClick={() => onChangePath("/")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <HomeIcon />
            </ListItemIcon>
            <Typography variant="inherit">Главная</Typography>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/devices"
            className={classes.menuItem}
            selected={nav.pagePath === "/devices"}
            onClick={() => onChangePath("/devices")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <MemoryIcon />
            </ListItemIcon>
            <Typography variant="inherit">Оборудование</Typography>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/events"
            className={classes.menuItem}
            selected={nav.pagePath === "/events"}
            onClick={() => onChangePath("/events")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <CompareArrowsIcon />
            </ListItemIcon>
            <Typography variant="inherit">События</Typography>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/commands"
            className={classes.menuItem}
            selected={nav.pagePath === "/commands"}
            onClick={() => onChangePath("/commands")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <DirectionsIcon />
            </ListItemIcon>
            <Typography variant="inherit">Команды</Typography>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/templates"
            className={classes.menuItem}
            selected={nav.pagePath === "/templates"}
            onClick={() => onChangePath("/templates")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <PowerInputIcon />
            </ListItemIcon>
            <Typography variant="inherit">Шаблоны</Typography>
          </MenuItem>

          <MenuItem
            component={Link}
            to="/firmwares"
            className={classes.menuItem}
            selected={nav.pagePath === "/firmwares"}
            onClick={() => onChangePath("/firmwares")}
          >
            <ListItemIcon className={classes.menuIcon}>
              <PublishIcon />
            </ListItemIcon>
            <Typography variant="inherit">Прошивки</Typography>
          </MenuItem>
        </MenuList>

        {isAdmin && (
          <MenuList onClick={() => onToggleNav(false)}>
            <Divider className={classes.divider} />

            <MenuItem
              component={Link}
              to="/projects"
              className={classes.menuItem}
              selected={nav.pagePath === "/projects"}
              onClick={() => onChangePath("/projects")}
            >
              <ListItemIcon className={classes.menuIcon}>
                <FolderIcon />
              </ListItemIcon>
              <Typography variant="inherit">Проекты</Typography>
            </MenuItem>

            <MenuItem
              component={Link}
              to="/users"
              className={classes.menuItem}
              selected={nav.pagePath === "/users"}
              onClick={() => onChangePath("/users")}
            >
              <ListItemIcon className={classes.menuIcon}>
                <PeopleAltIcon />
              </ListItemIcon>
              <Typography variant="inherit">Пользователи</Typography>
            </MenuItem>

            <MenuItem
              component={Link}
              to="/settings"
              className={classes.menuItem}
              selected={nav.pagePath === "/settings"}
              onClick={() => onChangePath("/settings")}
            >
              <ListItemIcon className={classes.menuIcon}>
                <SettingsIcon />
              </ListItemIcon>
              <Typography variant="inherit">Настройки</Typography>
            </MenuItem>
          </MenuList>
        )}
      </div>
    </Drawer>
  );
};
