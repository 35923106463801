import { connect } from "react-redux";
import { runCommandStartAction } from "../../store/Command/CommandAction";
import { deviceDirectoryRequestStartAction } from "../../store/Device/DeviceAction";
import { State } from "../../store/reducers";
import { templateDirectoryRequestStartAction } from "../../store/Template/TemplateAction";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  commandRunning: state.command.loading,
  deviceDirectory: state.device.directory.items,
  templateDirectory: state.template.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  runCommandStartAction: (
    outVersion: number,
    deviceId: number,
    params: string
  ) => dispatch(runCommandStartAction(outVersion, deviceId, params)),
  onRequestDeviceDirectory: () => dispatch(deviceDirectoryRequestStartAction()),
  onRequestTemplateDirectory: () =>
    dispatch(templateDirectoryRequestStartAction()),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
