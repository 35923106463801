import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({});

const mapDispatchToProps = (dispatch: any) => ({});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
