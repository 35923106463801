import { connect } from "react-redux";
import { State } from "../../store/reducers";
import {
  userBlockStartAction,
  usersRequestStartAction,
  userUnblockStartAction,
} from "../../store/User/UserAction";
import { UserFilter } from "../../store/User/UserModel";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  usersLoading: state.user.list.loading,
  users: state.user.list.items,
  pagination: state.user.list.pagination,
  filter: state.user.filter,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestUserList: (filter: UserFilter) =>
    dispatch(usersRequestStartAction(filter)),
  onRequestUserBlock: (id: number) => dispatch(userBlockStartAction(id)),
  onRequestUserUnblock: (id: number) => dispatch(userUnblockStartAction(id)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
