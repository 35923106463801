import agentInstance from "../../api/agent";
import { User, UserCreateForm, UserUpdateForm } from "./UserModel";

export const getUserList = (prjId: Number, params: {}): Promise<User[]> => {
  return new Promise<User[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`users/`, {
        params: params["filter"],
      })
    );
  });
};

export const apiUserCreate = (
  prjId: Number,
  form: UserCreateForm
): Promise<any> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).post(`users/`, form));
  });
};

export const apiUserUpdate = (
  prjId: Number,
  id: Number,
  form: UserUpdateForm
): Promise<any> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).patch(`users/` + id + `/`, form));
  });
};

export const apiUserBlock = (prjId: Number, params: {}): Promise<User[]> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).patch(`users/` + params["id"] + `/block/`));
  });
};
export const apiUserUnblock = (prjId: Number, params: {}): Promise<User[]> => {
  return new Promise<User[]>((resolve) => {
    resolve(agentInstance(prjId).patch(`users/` + params["id"] + `/unblock/`));
  });
};
