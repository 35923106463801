import * as types from "./EventTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  eventItemRequestCompletedAction,
  eventListRequestCompletedAction,
  eventListRequestFailedAction,
} from "./EventAction";
import { getEventItem, getEventList } from "./EventApis";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchEventRequestStart() {
  yield takeEvery(types.GET_EVENT_LIST_START, requestEventList);
  yield takeEvery(types.GET_EVENT_ITEM_START, requestEventItem);
}

const getProject = (state: State) => state.project.selected.id;

function* requestEventList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getEventList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    yield put(
      eventListRequestCompletedAction(resp.data, {
        CurrentPage: parseInt(resp.headers["x-pagination-current-page"]),
        PageCount: parseInt(resp.headers["x-pagination-page-count"]),
        per_page: parseInt(resp.headers["x-pagination-per-page"]),
        TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
      })
    );
  } catch (e) {
    yield put(eventListRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список событий",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestEventItem(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getEventItem, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }
     
    yield put(eventItemRequestCompletedAction(resp.data));
  } catch (e) {
    yield put(eventListRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить детали события",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}
