import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { Firmware } from "./FirmwareModel";

export const getFirmwareList = (prjId: Number, params: {}): Promise<Firmware[]> => {
    return new Promise<Firmware[]>((resolve) => {
        resolve(
            agentInstance(prjId).get(`firmwares/`, {
                params: params["filter"],
            })
        );
    });
};

export const getFirmwareDirectory = (prjId: Number, params: {}): Promise<Directory[]> => {
    return new Promise<Directory[]>((resolve) => {
        resolve(agentInstance(prjId).get(`firmwares/dir/`));
    });
};

export const postFirmwareCreate = (prjId: Number, params: {}): Promise<Firmware[]> => {
    return new Promise<Firmware[]>((resolve) => {
        resolve(agentInstance(prjId).post(`firmwares/`, params["formData"]));
    });
};

export const postFirmwareFlash = (prjId: Number, params: {}): Promise<any> => {
    return new Promise<any>((resolve) => {
        resolve(
            agentInstance(prjId).post(`firmwares/` + params["firmwareId"] + `/flash/`, {
                devices: params["devices"],
                sendCommand: params["sendCommand"],
                modbus: params["modbus"],
            })
        );
    });
};

export const apiFirmwareDelete = (prjId: Number, params: {}): Promise<Firmware[]> => {
    return new Promise<Firmware[]>((resolve) => {
        resolve(agentInstance(prjId).delete(`firmwares/` + params["id"] + `/`));
    });
};

export const apiFirmwareSetDefault = (prjId: Number, params: {}): Promise<Firmware[]> => {
    return new Promise<Firmware[]>((resolve) => {
        resolve(agentInstance(prjId).patch(`firmwares/` + params["id"] + `/`, {
            action: "set-default",
        }));
    });
};

