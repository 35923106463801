import {
  Backdrop,
  Button,
  CircularProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import VisibilityIcon from "@material-ui/icons/Visibility";
import React from "react";
import DateTime from "../../components/formatters/date-time";
import ProjectStatus from "../../components/project-status";
import TableSortHeader from "../../components/table-sort-header";
import useFilters from "../../hooks/useFilters";
import { Directory } from "../../store/Common/CommonModel";
import { Project, ProjectFilter } from "../../store/Project/ProjectModel";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  onRequestProjectList: (filter: ProjectFilter) => void;
  projectsLoading: boolean;
  projects: Project[];
  filter: ProjectFilter;
  selectedProject: Directory;
  settings: Settings;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const { projects, filter, settings, projectsLoading, onRequestProjectList } =
    props;

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestProjectList
  );

  return (
    <>
      <h1>Проекты</h1>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left">
                <TableSortHeader
                  id="name"
                  title="Название"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="status"
                  title="Статус"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="created_at"
                  title="Время создания"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right">Действия</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {projects &&
              0 < projects.length &&
              projects.map((item) => (
                <TableRow hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell component="th" scope="row">
                    {item.name}
                  </TableCell>
                  <TableCell align="center">
                    <ProjectStatus value={item.status} />
                  </TableCell>
                  <TableCell align="center">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.created_at}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.buttonView}
                      startIcon={<VisibilityIcon />}
                      disabled
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            {(!projects || projects.length < 1) && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      <Backdrop className={classes.backdrop} open={projectsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
