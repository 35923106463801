import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import InputFile from "../components/input-file";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

const FirmwareUploadForm = (props: any) => {
  const classes = useStyles();
  const { fields } = props;

  const [type, setType] = React.useState<"" | "os" | "ppo" | "bvv">(
    fields.type
  );

  const handleChangeType = (event: any) => {
    setType(event.target.value);
    fields.type = event.target.value;
  };

  const handleChangeFile = (file: File) => {
    fields.file = file;
  };

  return (
    <>
      <FormControl className={classes.formControl}>
        <FormLabel component="legend">Тип прошивка</FormLabel>
        <RadioGroup
          row
          aria-label="gender"
          name="gender1"
          value={type}
          onChange={handleChangeType}
        >
          <FormControlLabel value="os" control={<Radio />} label="ОС" />
          <FormControlLabel value="ppo" control={<Radio />} label="ППО" />
          <FormControlLabel value="bvv" control={<Radio />} label="БВВ" />
        </RadioGroup>
      </FormControl>

      <FormControl className={classes.formControl}>
        <FormLabel component="legend">Файл прошивки</FormLabel>
        <InputFile onChange={handleChangeFile} />
      </FormControl>
    </>
  );
};

export default FirmwareUploadForm;
