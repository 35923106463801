import { TableSortLabel } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
  })
);

const TableSortHeader = (props: any) => {
  const classes = useStyles();
  const { title, id, orderBy, order, onChange } = props;

  return (
    <TableSortLabel
      active={orderBy === id}
      direction={orderBy === id ? order : "asc"}
      onClick={() => onChange(id)}
    >
      {title}
      {orderBy === id ? (
        <span className={classes.visuallyHidden}>
          {order === "desc" ? "sorted descending" : "sorted ascending"}
        </span>
      ) : null}
    </TableSortLabel>
  );
};

export default TableSortHeader;
