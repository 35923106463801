import * as types from "./TemplateTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  apiTemplateCreate,
  apiTemplateUpdate,
  getTemplateDirectory,
  getTemplateList,
} from "./TemplateApis";
import {
  templateCreateRequestCompletedAction,
  templateCreateRequestFailedAction,
  templateDirectoryRequestCompletedAction,
  templateDirectoryRequestFailedAction,
  templateUpdateRequestCompletedAction,
  templateUpdateRequestFailedAction,
  templatesRequestCompletedAction,
  templatesRequestFailedAction,
  templatesRequestStartAction,
} from "./TemplateAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchTemplateRequestStart() {
  yield takeEvery(types.GET_TEMPLATE_LIST_START, requestTemplateList);
  yield takeEvery(types.GET_TEMPLATE_DIRECTORY_START, requestTemplateDirectory);

  yield takeEvery(types.CREATE_TEMPLATE_START, requestCreateTemplate);
  yield takeEvery(types.UPDATE_TEMPLATE_START, requestUpdateTemplate);
}

const getProject = (state: State) => state.project.selected.id;
const getFilter = (state: State) => state.template.filter;

function* requestTemplateList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getTemplateList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(
      templatesRequestCompletedAction(resp.data, {
        CurrentPage: parseInt(resp.headers["x-pagination-current-page"]),
        PageCount: parseInt(resp.headers["x-pagination-page-count"]),
        per_page: parseInt(resp.headers["x-pagination-per-page"]),
        TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
      })
    );
  } catch (e) {
    yield put(templatesRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список шаблонов",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestTemplateDirectory(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getTemplateDirectory, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(templateDirectoryRequestCompletedAction(resp.data));
  } catch (e) {
    yield put(templateDirectoryRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список шаблонов",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestCreateTemplate(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const resp = yield call(apiTemplateCreate, prjId, action.payload.form);

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(templateCreateRequestCompletedAction());
  } catch (e) {
    yield put(templateCreateRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось создать шаблон",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(templatesRequestStartAction(filter));
}

function* requestUpdateTemplate(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const resp = yield call(
      apiTemplateUpdate,
      prjId,
      action.payload.id,
      action.payload.form
    );

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(templateUpdateRequestCompletedAction());
  } catch (e) {
    yield put(templateUpdateRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось сохранить шаблон",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(templatesRequestStartAction(filter));
}
