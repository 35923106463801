export const GET_TEMPLATE_LIST_START = "GET_TEMPLATE_LIST - Start";
export const GET_TEMPLATE_LIST_COMPLETED = "GET_TEMPLATE_LIST - Completed";
export const GET_TEMPLATE_LIST_FAILED = "GET_TEMPLATE_LIST - Failed";

export const GET_TEMPLATE_DIRECTORY_START = "GET_TEMPLATE_DIRECTORY - Start";
export const GET_TEMPLATE_DIRECTORY_COMPLETED =
  "GET_TEMPLATE_DIRECTORY - Completed";
export const GET_TEMPLATE_DIRECTORY_FAILED = "GET_TEMPLATE_DIRECTORY - Failed";

export const CREATE_TEMPLATE_START = "CREATE_TEMPLATE - Start";
export const CREATE_TEMPLATE_COMPLETED = "CREATE_TEMPLATE - Completed";
export const CREATE_TEMPLATE_FAILED = "CREATE_TEMPLATE - Failed";

export const UPDATE_TEMPLATE_START = "UPDATE_TEMPLATE - Start";
export const UPDATE_TEMPLATE_COMPLETED = "UPDATE_TEMPLATE - Completed";
export const UPDATE_TEMPLATE_FAILED = "UPDATE_TEMPLATE - Failed";
