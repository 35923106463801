
export const GET_FIRMWARE_LIST_START = "GET_FIRMWARE_LIST - Start";
export const GET_FIRMWARE_LIST_COMPLETED = "GET_FIRMWARE_LIST - Completed";
export const GET_FIRMWARE_LIST_FAILED = "GET_FIRMWARE_LIST - Failed";

export const GET_FIRMWARE_ITEM_START = "GET_FIRMWARE_ITEM - Start";
export const GET_FIRMWARE_ITEM_COMPLETED = "GET_FIRMWARE_ITEM - Completed";
export const GET_FIRMWARE_ITEM_FAILED = "GET_FIRMWARE_ITEM - Failed";

export const CREATE_FIRMWARE_START = "CREATE_FIRMWARE - Start";
export const CREATE_FIRMWARE_COMPLETED = "CREATE_FIRMWARE - Completed";
export const CREATE_FIRMWARE_FAILED = "CREATE_FIRMWARE - Failed";

export const FLASH_FIRMWARE_START = "FLASH_FIRMWARE - Start";
export const FLASH_FIRMWARE_COMPLETED = "FLASH_FIRMWARE - Completed";
export const FLASH_FIRMWARE_FAILED = "FLASH_FIRMWARE - Failed";

export const DELETE_FIRMWARE_START = "DELETE_FIRMWARE - Start";
export const DELETE_FIRMWARE_COMPLETED = "DELETE_FIRMWARE - Completed";
export const DELETE_FIRMWARE_FAILED = "DELETE_FIRMWARE - Failed";

export const SET_DEFAULT_FIRMWARE_START = "SET_DEFAULT_FIRMWARE - Start";
export const SET_DEFAULT_FIRMWARE_COMPLETED = "SET_DEFAULT_FIRMWARE - Completed";
export const SET_DEFAULT_FIRMWARE_FAILED = "SET_DEFAULT_FIRMWARE - Failed";

export const GET_FIRMWARE_DIRECTORY_START = "GET_FIRMWARE_DIRECTORY - Start";
export const GET_FIRMWARE_DIRECTORY_COMPLETED = "GET_FIRMWARE_DIRECTORY - Completed";
export const GET_FIRMWARE_DIRECTORY_FAILED = "GET_FIRMWARE_DIRECTORY - Failed";
