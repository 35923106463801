import {
  AppBar,
  Button,
  ClickAwayListener,
  Grow,
  IconButton,
  Menu,
  MenuItem,
  MenuList,
  Paper,
  Popper,
  Toolbar,
  Typography,
} from "@material-ui/core";
import React, { useEffect } from "react";
import { Theme, createStyles, makeStyles } from "@material-ui/core/styles";

import AccountCircle from "@material-ui/icons/AccountCircle";
import { AuthState } from "../../store/Auth/AuthReducer";
import { Directory } from "../../store/Common/CommonModel";
import ExpandMore from "@material-ui/icons/ExpandMore";
import MenuIcon from "@material-ui/icons/Menu";
import { NavState } from "../../store/Nav/NavReducer";
import clsx from "clsx";

interface Props {
  onSignOutAction: () => void;
  onToggleNav: (isOpen: boolean) => void;
  onSelectProject: (prj: Directory) => void;
  onRequestProjectDirectory: () => void;
  nav: NavState;
  auth: AuthState;
  projectDirectory: Directory[];
  selectedProject: Directory;
}

const drawerWidth = 240;

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    appBar: {
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
    },
    appBarShift: {
      width: `calc(100% - ${drawerWidth}px)`,
      marginLeft: drawerWidth,
      transition: theme.transitions.create(["margin", "width"], {
        easing: theme.transitions.easing.easeOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    menuButton: {
      marginRight: theme.spacing(2),
    },
    selectProject: {
      position: "relative",
      // borderRadius: theme.shape.borderRadius,
      marginRight: theme.spacing(2),
      marginLeft: 0,
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        marginLeft: theme.spacing(3),
        width: "auto",
      },
    },
    selectButton: {
      color: "#FFFFFF",
    },
    grow: {
      flexGrow: 1,
    },
    sectionUser: {
      // display: 'flex',
    },
    title: {
      // flexGrow: 1,
    },
    hide: {
      display: "none",
    },
  })
);

export const HeaderComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  const {
    onSignOutAction,
    onRequestProjectDirectory,
    onSelectProject,
    onToggleNav,
    nav,
    auth,
    projectDirectory,
    selectedProject,
  } = props;

  const anchorRef = React.useRef<HTMLButtonElement>(null);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [profileMenuOpen, setProfileMenuOpen] = React.useState<boolean>(false);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSelect = (prj: Directory) => {
    if (prj.id != selectedProject.id) {
      onSelectProject(prj);
    }
    handleClose();
  };

  const handleProfileMenuOpen = () => {
    setProfileMenuOpen((prevProfileMenuOpen) => !prevProfileMenuOpen);
  };

  const profileMenuClose = () => {
    setProfileMenuOpen(false);
  };

  const handleSignOut = () => {
    onSignOutAction();
  };

  useEffect(() => {
    onRequestProjectDirectory();
  }, []);

  return (
    <>
      <div className={classes.root} id="header">
        <AppBar
          position="fixed"
          className={clsx(classes.appBar, {
            [classes.appBarShift]: nav.isOpen,
          })}
        >
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="Open menu"
              onClick={() => onToggleNav(true)}
              edge="start"
              className={clsx(
                classes.menuButton,
                (nav.isOpen || !auth.isAuthenticated) && classes.hide
              )}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" className={classes.title} noWrap>
              AXIOT
            </Typography>

            <div className={classes.selectProject}>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={handleClick}
                endIcon={<ExpandMore />}
                className={classes.selectButton}
              >
                {selectedProject && <>{selectedProject.title}</>}

                {(!selectedProject || !selectedProject.title) && (
                  <>Выберите проект</>
                )}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                keepMounted
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                {projectDirectory &&
                  0 < projectDirectory.length &&
                  projectDirectory.map((item) => (
                    <MenuItem
                      onClick={() => handleSelect(item)}
                      selected={item.id === selectedProject.id}
                      key={"prj-" + item.id}
                    >
                      {item.title}
                    </MenuItem>
                  ))}
              </Menu>
            </div>

            <div className={classes.grow} />

            <div className={classes.sectionUser}>
              <IconButton
                edge="end"
                aria-label="account of current user"
                aria-haspopup="true"
                color="inherit"
                ref={anchorRef}
                aria-controls={profileMenuOpen ? "menu-list-grow" : undefined}
                onClick={handleProfileMenuOpen}
              >
                <AccountCircle />
              </IconButton>

              <Popper
                open={profileMenuOpen}
                anchorEl={anchorRef.current}
                role={undefined}
                transition
                disablePortal
              >
                {({ TransitionProps, placement }) => (
                  <Grow
                    {...TransitionProps}
                    style={{
                      transformOrigin:
                        placement === "bottom" ? "center top" : "center bottom",
                    }}
                  >
                    <Paper>
                      <ClickAwayListener onClickAway={profileMenuClose}>
                        <MenuList
                          autoFocusItem={profileMenuOpen}
                          id="menu-list-grow"
                        >
                          {/* <MenuItem onClick={profileMenuClose}>
                            Profile
                          </MenuItem>
                          <MenuItem onClick={profileMenuClose}>
                            Settings
                          </MenuItem>

                          <Divider /> */}

                          <MenuItem onClick={handleSignOut}>Logout</MenuItem>
                        </MenuList>
                      </ClickAwayListener>
                    </Paper>
                  </Grow>
                )}
              </Popper>
            </div>
          </Toolbar>
        </AppBar>
      </div>
    </>
  );
};
