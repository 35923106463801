import {
  Divider,
  FormControl,
  InputLabel,
  ListSubheader,
  MenuItem,
  Select,
} from "@material-ui/core";
import {
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from "@material-ui/core/styles";

import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: 0,
      minWidth: 140,
      width: "100%",
    },
    optionGroup: {
      background: "white",
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    debug: {},
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
  })
);

export const OutVersionFilter = (props: any) => {
  const classes = useStyles();
  const { value, variants, onChange } = props;

  const [filter, setFilter] = React.useState<number>(value);
  const [openFilter, setOpenFilter] = React.useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setFilter(event.target.value as number);
    onChange(event.target.value);
  };

  const handleClose = () => {
    setOpenFilter(false);
  };

  const handleOpen = () => {
    setOpenFilter(true);
  };

  return (
    <FormControl className={classes.formControl}>
      <InputLabel id="demo-controlled-open-select-label">
        Версия вх./вых.
      </InputLabel>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        open={openFilter}
        onClose={handleClose}
        onOpen={handleOpen}
        value={filter}
        onChange={handleChange}
      >
        <MenuItem value={0}>
          <em>Все</em>
        </MenuItem>
        <MenuItem value={-1}>
          <em>Все кроме `PING` / `PONG`</em>
        </MenuItem>
        <MenuItem value={-2}>
          <em>Неизвестные</em>
        </MenuItem>
        <MenuItem value={-3}>
          <em>Сетевые статусы</em>
        </MenuItem>
        <Divider />
        <ListSubheader className={classes.optionGroup}>События</ListSubheader>
        {variants &&
          variants
            .filter((item) => {
              return item.group === "event";
            })
            .map((item) => (
              <MenuItem
                className={classes.event}
                value={item.id}
                key={"filter-outversion-" + item.id}
              >
                {item.title}
              </MenuItem>
            ))}
        <ListSubheader className={classes.optionGroup}>
          Отладочные события
        </ListSubheader>
        {variants &&
          variants
            .filter((item) => {
              return item.group === "debug";
            })
            .map((item) => (
              <MenuItem
                className={classes.debug}
                value={item.id}
                key={"filter-outversion-" + item.id}
              >
                {item.title}
              </MenuItem>
            ))}
        <ListSubheader className={classes.optionGroup}>Ошибки</ListSubheader>
        {variants &&
          variants
            .filter((item) => {
              return item.group === "error";
            })
            .map((item) => (
              <MenuItem
                className={classes.error}
                value={item.id}
                key={"filter-outversion-" + item.id}
              >
                {item.title}
              </MenuItem>
            ))}
        <ListSubheader className={classes.optionGroup}>Команды</ListSubheader>
        {variants &&
          variants
            .filter((item) => {
              return item.group === "command";
            })
            .map((item) => (
              <MenuItem
                className={classes.command}
                value={item.id}
                key={"filter-outversion-" + item.id}
              >
                {item.title}
              </MenuItem>
            ))}
      </Select>
    </FormControl>
  );
};
