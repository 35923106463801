import { BaseAction } from "../Common/CommonModel";
import * as types from './CommandTypes';

export type CommandState = {
    loading: boolean;
    result: string;
};

export const commandReducer = (
    state: CommandState = {
        loading: false,
        result: null,
    },
    action: BaseAction
) => {
    switch (action.type) {
        case types.RUN_COMMAND_START:
            return {
                ...state,
                loading: true,
                result: null,
            };

        case types.RUN_COMMAND_COMPLETED:
            return {
                ...state,
                loading: false,
            };

        case types.RUN_COMMAND_FAILED:
            return {
                ...state,
                loading: false,
            };
    }

    return state;
};
