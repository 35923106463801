import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import {
  PROJECT_STATUS_ACTIVE,
  PROJECT_STATUS_INACTIVE,
} from "../store/Project/ProjectConstant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    active: {
      background: "#00a65a",
      color: "#FFF",
      padding: "4px 10px",
    },
    inactive: {
      background: "#dd4b39",
      color: "#FFF",
      padding: "4px 10px",
    },
  })
);

const ProjectStatus = (props: any) => {
  const classes = useStyles();
  const { value } = props;

  if (value === PROJECT_STATUS_ACTIVE) {
    return (
      <Badge badgeContent="Активен" classes={{ badge: classes.active }}></Badge>
    );
  }

  if (value === PROJECT_STATUS_INACTIVE) {
    return (
      <Badge
        badgeContent="Не&nbsp;активен"
        classes={{ badge: classes.inactive }}
      ></Badge>
    );
  }

  return <>&nbsp;</>;
};

export default ProjectStatus;
