import * as types from './NavTypes';

import { BaseAction } from "../Common/CommonModel";

export const changePathAction = (path: string): BaseAction => ({
    type: types.CHANGE_PATH,
    payload: {
        path: path,
    },
});

export const toggleNavAction = (isOpen: boolean): BaseAction => ({
    type: types.TOGGLE_NAV,
    payload: {
        isOpen: isOpen,
    },
});
