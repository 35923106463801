import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import {
  Template,
  TemplateCreateForm,
  TemplateUpdateForm,
} from "./TemplateModel";

export const getTemplateList = (
  prjId: Number,
  params: {}
): Promise<Template[]> => {
  return new Promise<Template[]>((resolve) => {
    resolve(
      agentInstance(prjId).get(`templates/`, {
        params: params["filter"],
      })
    );
  });
};

export const getTemplateDirectory = (
  prjId: Number,
  params: {}
): Promise<Directory[]> => {
  return new Promise<Directory[]>((resolve) => {
    resolve(agentInstance(prjId).get(`templates/dir/`, params));
  });
};

export const apiTemplateCreate = (
  prjId: Number,
  form: TemplateCreateForm
): Promise<any> => {
  return new Promise<Template[]>((resolve) => {
    resolve(agentInstance(prjId).post(`templates/`, form));
  });
};

export const apiTemplateUpdate = (
  prjId: Number,
  id: Number,
  form: TemplateUpdateForm
): Promise<any> => {
  return new Promise<Template[]>((resolve) => {
    resolve(agentInstance(prjId).patch(`templates/` + id + `/`, form));
  });
};
