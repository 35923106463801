import { changePathAction, toggleNavAction } from "../../store/Nav/NavActions";

import { NavComponent } from "./nav.component";
import { State } from "../../store/reducers";
import { connect } from "react-redux";

const mapStateToProps = (state: State) => ({
  nav: state.nav,
  selectedProject: state.project.selected,
  isAdmin: state.auth.isAdmin,
});

const mapDispatchToProps = (dispatch: any) => ({
  onToggleNav: (isOpen: boolean) => dispatch(toggleNavAction(isOpen)),
  onChangePath: (path: string) => dispatch(changePathAction(path)),
});

export const NavContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(NavComponent);
