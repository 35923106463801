import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Hidden,
  IconButton,
  LinearProgress,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import LoyaltyIcon from "@material-ui/icons/Loyalty";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { useConfirm } from "material-ui-confirm";
import React from "react";
import DateTime from "../../components/formatters/date-time";
import TableSortHeader from "../../components/table-sort-header";
import { FirmwareFlashContainer } from "../../forms/firmware-flash";
import FirmwareUploadForm from "../../forms/firmware-upload";
import useFilters from "../../hooks/useFilters";
import { Directory, Pagination } from "../../store/Common/CommonModel";
import { Firmware, FirmwareFilter } from "../../store/Firmware/FirmwareModel";
import { Settings } from "../../store/Settings/SettingsModel";

interface Props {
  onRequestFirmwareList: (filter: FirmwareFilter) => void;
  onRequestFirmwareCreate: (data: FormData) => void;
  onRequestFirmwareDelete: (id: number) => void;
  onRequestFirmwareSetDefault: (id: number) => void;
  onRequestDeviceDirectory: () => void;
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) => void;
  firmwaresLoading: boolean;
  firmwares: Firmware[];
  firmwareUploading: boolean;
  pagination: Pagination;
  filter: FirmwareFilter;
  settings: Settings;
  deviceDirectory: Directory[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonFlash: {
      margin: theme.spacing(1),
      // marginRight: 0,
    },
    headCell: {
      paddingTop: 25,
      verticalAlign: "top",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    fields: {
      display: "block",
    },
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    buttonUpload: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
    uploadInput: {
      // display: 'none',
    },
    default: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const {
    firmwaresLoading,
    firmwares,
    firmwareUploading,
    pagination,
    filter,
    settings,
    onRequestFirmwareList,
    onRequestFirmwareCreate,
    onRequestFirmwareDelete,
    onRequestFirmwareSetDefault,
  } = props;

  // ***

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestFirmwareList
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleChangeValue({ page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  /* * */

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  /* Загрузка прошивки */

  const [openUpload, setOpenUpload] = React.useState<boolean>(false);
  const uploadForm = {
    type: "os",
    file: null,
  };

  const handleOpenUpload = () => {
    uploadForm.type = "os";
    uploadForm.file = null;

    setOpenUpload(true);
  };

  const handleCloseUpload = () => {
    setOpenUpload(false);
  };

  const handleStartUpload = () => {
    if (!uploadForm.type) {
      alert("Validation Error: `Тип прошивка`");
      return;
    }
    if (!uploadForm.file) {
      alert("Validation Error: `Файл прошивки`");
      return;
    }

    const data = new FormData();
    data.append("type", uploadForm.type);
    data.append("file", uploadForm.file);
    onRequestFirmwareCreate(data);

    handleCloseUpload();
  };

  /* Прошивка контроллеров */

  const [openFlash, setOpenFlash] = React.useState<Firmware>(null);

  const flashForm = {
    devices: [],
    sendCommand: true,
    modbus: 0,
  };

  const handleOpenFlash = (firmware: Firmware) => () => {
    flashForm.devices = [];
    flashForm.sendCommand = true;
    flashForm.modbus = 0;

    setOpenFlash(firmware);
  };

  const handleCloseFlash = () => {
    setOpenFlash(null);
  };

  /* * */

  const handleDelete = (firmware: Firmware) => () => {
    confirm({
      title: "Вы точно хотите удалить прошивку?",
      description: "Файл прошивки будет удален безвозвратно!",
    }).then(() => {
      onRequestFirmwareDelete(firmware.id);
    });
  };

  /* * */

  const handleSetDefault = (firmware: Firmware) => () => {
    onRequestFirmwareSetDefault(firmware.id);
  };

  /* * */

  const getRowStyle = (item: Firmware) => {
    if (item.is_default) {
      return classes.default;
    }
    return null;
  };

  return (
    <>
      <h1>Прошивки</h1>

      <TableContainer>
        <Table size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left">
                <TableSortHeader
                  id="origin_filename"
                  title="Оригинальное имя файла"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="f_type,f_version"
                  title="Тип.Версия"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="type"
                  title="Тип прошивки"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="file_size"
                  title="Размер файла"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="created_at"
                  title="Время создания"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" width="180">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {firmwares &&
              0 < firmwares.length &&
              firmwares.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.origin_name}</TableCell>
                  <TableCell align="center">
                    {item.f_type}.{item.f_version}
                  </TableCell>
                  <TableCell align="center">{item.type}</TableCell>
                  <TableCell align="center">{item.file_size}</TableCell>
                  <TableCell align="center">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.created_at}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="secondary"
                        onClick={handleSetDefault(item)}
                        disabled={item.type == "bvv" || item.is_default}
                      >
                        <Tooltip title="Назначить прошивку дефолтной">
                          <LoyaltyIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        color="primary"
                        onClick={handleOpenFlash(item)}
                      >
                        <Tooltip title="Прошить">
                          <FlashOnIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton
                        onClick={handleDelete(item)}
                        disabled={item.is_default}
                      >
                        <Tooltip title="Удалить прошивку">
                          <DeleteIcon />
                        </Tooltip>
                      </IconButton>
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleSetDefault(item)}
                            disabled={item.type == "bvv" || item.is_default}
                          >
                            Назначить дефолтной
                          </MenuItem>

                          <MenuItem
                            color="primary"
                            onClick={handleOpenFlash(item)}
                          >
                            Прошить
                          </MenuItem>

                          <MenuItem
                            onClick={handleDelete(item)}
                            disabled={item.is_default}
                          >
                            Удалить
                          </MenuItem>
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!firmwares || firmwares.length < 1) && (
              <TableRow>
                <TableCell colSpan={7} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.PageCount || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.TotalCount}
          page={pagination.CurrentPage}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseUpload}
        aria-labelledby="customized-dialog-title"
        open={openUpload}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          Загрузка прошивки
        </DialogTitle>
        <DialogContent dividers>
          <LinearProgress color="secondary" hidden={!firmwareUploading} />

          <FirmwareUploadForm fields={uploadForm} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseUpload} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleStartUpload}
            variant="contained"
            color="primary"
          >
            Upload
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseFlash}
        aria-labelledby="customized-dialog-title"
        open={openFlash != null && 0 < openFlash.id}
        maxWidth="md"
        fullWidth={true}
      >
        <FirmwareFlashContainer
          firmware={openFlash}
          handleCloseFlash={handleCloseFlash}
        />
      </Dialog>

      <Backdrop
        className={classes.backdrop}
        open={firmwaresLoading || firmwareUploading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>

      <Fab
        color="primary"
        aria-label="add"
        className={classes.buttonUpload}
        onClick={handleOpenUpload}
      >
        <AddIcon />
      </Fab>
    </>
  );
};
