import agentInstance from "../../api/agent";
import { Device } from "../Device/DeviceModel";

export const apiRunCommand = (prjId: Number, params: {}): Promise<Device[]> => {
    return new Promise<Device[]>((resolve) => {
        resolve(
            agentInstance(prjId).post(`commands/`, {
                out_version: params["outVersion"],
                device_id: params["deviceId"],
                params: params["params"],
            })
        );
    });
};
