import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import {
  createStyles,
  lighten,
  makeStyles,
  Theme,
} from "@material-ui/core/styles";
import AddIcon from "@material-ui/icons/Add";
import BlockIcon from "@material-ui/icons/Block";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import VisibilityIcon from "@material-ui/icons/Visibility";
import { useConfirm } from "material-ui-confirm";
import { default as React } from "react";
import LogicStatus from "../../components/logic-status";
import TableSortHeader from "../../components/table-sort-header";
import UserStatus from "../../components/user-status";
import { UserCreateContainer } from "../../forms/user-create";
import { UserUpdateContainer } from "../../forms/user-update";
import useFilters from "../../hooks/useFilters";
import { Pagination } from "../../store/Common/CommonModel";
import { Settings } from "../../store/Settings/SettingsModel";
import {
  USER_STATUS_ACTIVE,
  USER_STATUS_INACTIVE,
} from "../../store/User/UserConstant";
import { User, UserFilter } from "../../store/User/UserModel";

interface Props {
  usersLoading: boolean;
  users: User[];
  settings: Settings;
  pagination: Pagination;
  filter: UserFilter;
  onRequestUserList: (filter: UserFilter) => void;
  onRequestUserBlock: (id: number) => void;
  onRequestUserUnblock: (id: number) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 25,
      verticalAlign: "top",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    buttonCreate: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const {
    usersLoading,
    users,
    filter,
    pagination,
    onRequestUserList,
    onRequestUserBlock,
    onRequestUserUnblock,
  } = props;

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestUserList
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleChangeValue({ page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  // ***

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  // ***

  const [open, setOpen] = React.useState(false);
  const [detailed, setDetailed] = React.useState<User>();

  const handleOpenDetails = (item: User) => () => {
    setDetailed(item);
    setOpen(true);
  };

  const handleCloseDetails = () => {
    setOpen(false);
    // setDetailed(null);
  };

  // ***

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  // Редактирование пользователя

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [updating, setUpdating] = React.useState<User>();

  const handleOpenUpdate = (item: User) => () => {
    setUpdating(item);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  // ***

  const handleBlock = (user: User) => () => {
    confirm({
      title: "Вы точно хотите заблокировать пользователя?",
      description: "",
    }).then(() => {
      onRequestUserBlock(user.id);
    });
  };

  const handleUnblock = (user: User) => () => {
    onRequestUserUnblock(user.id);
  };

  // ***

  const getRowStyle = (item: User) => {
    switch (item.status) {
      case 0:
        return classes.event;

      case 1:
        return classes.command;

      //   case "error":
      //     return classes.error;

      default:
        return null;
    }
  };

  return (
    <>
      <h1>Пользователи</h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="first_name,last_name"
                  title="Имя"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="email"
                  title="E-mail"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="status"
                  title="Статус"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="is_admin"
                  title="Админ"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {users &&
              0 < users.length &&
              users.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="center">{item.id}</TableCell>
                  <TableCell align="left">
                    {item.first_name} {item.last_name}
                  </TableCell>
                  <TableCell align="left">{item.email}</TableCell>
                  <TableCell align="center">
                    <UserStatus value={item.status} />
                  </TableCell>
                  <TableCell align="center">
                    <LogicStatus value={item.is_admin} />
                  </TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="primary"
                        onClick={handleOpenDetails(item)}
                      >
                        <Tooltip title="Детали">
                          <VisibilityIcon />
                        </Tooltip>
                      </IconButton>

                      <IconButton onClick={handleOpenUpdate(item)}>
                        <Tooltip title="Редактировать">
                          <EditIcon />
                        </Tooltip>
                      </IconButton>

                      {item.status == USER_STATUS_ACTIVE && (
                        <IconButton
                          color="secondary"
                          onClick={handleBlock(item)}
                        >
                          <Tooltip title="Заблокировать">
                            <BlockIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                      {item.status == USER_STATUS_INACTIVE && (
                        <IconButton
                          color="primary"
                          onClick={handleUnblock(item)}
                        >
                          <Tooltip title="Разблокировать">
                            <BlockIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenDetails(item)}
                          >
                            Детали
                          </MenuItem>

                          <MenuItem onClick={handleOpenUpdate(item)}>
                            Редактировать
                          </MenuItem>

                          {item.status == USER_STATUS_ACTIVE && (
                            <MenuItem onClick={handleBlock(item)}>
                              Заблокировать
                            </MenuItem>
                          )}
                          {item.status == USER_STATUS_INACTIVE && (
                            <MenuItem onClick={handleUnblock(item)}>
                              Разблокировать
                            </MenuItem>
                          )}
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!users || users.length === 0) && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.PageCount || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.TotalCount}
          page={pagination.CurrentPage}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Fab
        color="primary"
        aria-label="add"
        className={classes.buttonCreate}
        onClick={handleOpenCreate}
      >
        <AddIcon />
      </Fab>

      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        {detailed && (
          <>
            <DialogTitle id="customized-dialog-title">
              Пользователь: <b>#{detailed.id}</b>
            </DialogTitle>
            <DialogContent dividers>
              <ul>
                <li>
                  <b>ID:</b> {detailed.id}
                </li>
                <li>
                  <b>Имя:</b> {detailed.first_name}
                </li>
                <li>
                  <b>Фамилия:</b> {detailed.last_name}
                </li>
                <li>
                  <b>E-mail:</b> {detailed.email}
                </li>
                <li>
                  <b>Телефон:</b> {detailed.phone}
                </li>
                <li>
                  <b>Статус:</b> &nbsp; &nbsp; &nbsp;{" "}
                  <UserStatus value={detailed.status} />
                </li>
                <li>
                  <b>Админ:</b> &nbsp; &nbsp; &nbsp;{" "}
                  <LogicStatus value={detailed.is_admin} />
                </li>
              </ul>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseCreate}
        aria-labelledby="customized-dialog-title"
        open={openCreate}
        maxWidth="md"
        fullWidth={true}
      >
        <UserCreateContainer handleClose={handleCloseCreate} />
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseUpdate}
        aria-labelledby="customized-dialog-title"
        open={openUpdate}
        maxWidth="md"
        fullWidth={true}
      >
        <UserUpdateContainer handleClose={handleCloseUpdate} user={updating} />
      </Dialog>

      <Backdrop className={classes.backdrop} open={usersLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
