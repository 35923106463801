import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import {
  PROTOCOL_MQTT_V1,
  PROTOCOL_MQTT_V2,
} from "../store/Device/DeviceConstant";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    mqtt: {
      background: "#00a65a",
      color: "#FFF",
      padding: "4px 10px",
    },
  })
);

const DeviceStatus = (props: any) => {
  const classes = useStyles();
  const { value } = props;

  if (value === PROTOCOL_MQTT_V1) {
    return (
      <Badge
        badgeContent="MQTT&nbsp;/&nbsp;1"
        classes={{ badge: classes.mqtt }}
      ></Badge>
    );
  }

  if (value === PROTOCOL_MQTT_V2) {
    return (
      <Badge
        badgeContent="MQTT&nbsp;/&nbsp;2"
        classes={{ badge: classes.mqtt }}
      ></Badge>
    );
  }

  return <>&nbsp;</>;
};

export default DeviceStatus;
