import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { templatesRequestStartAction } from "../../store/Template/TemplateAction";
import { TemplateFilter } from "../../store/Template/TemplateModel";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  templatesLoading: state.template.list.loading,
  templates: state.template.list.items,
  pagination: state.template.list.pagination,
  filter: state.template.filter,
  settings: state.settings,
  isAdmin: state.auth.isAdmin,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestTemplateList: (filter: TemplateFilter) =>
    dispatch(templatesRequestStartAction(filter)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
