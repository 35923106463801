import { BaseAction, Pagination } from "../Common/CommonModel";
import { User, UserCreateForm, UserFilter, UserUpdateForm } from "./UserModel";
import * as types from "./UserTypes";

// ***

export const usersRequestStartAction = (filter: UserFilter): BaseAction => ({
  type: types.GET_USER_LIST_START,
  payload: {
    filter: filter,
  },
});

export const usersRequestCompletedAction = (
  list: User[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_USER_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const usersRequestFailedAction = (): BaseAction => ({
  type: types.GET_USER_LIST_FAILED,
  payload: {},
});

// ***

export const userCreateRequestStartAction = (
  form: UserCreateForm
): BaseAction => ({
  type: types.CREATE_USER_START,
  payload: {
    form: form,
  },
});

export const userCreateRequestCompletedAction = (): BaseAction => ({
  type: types.CREATE_USER_COMPLETED,
  payload: {},
});

export const userCreateRequestFailedAction = (): BaseAction => ({
  type: types.CREATE_USER_FAILED,
  payload: {},
});

// ***

export const userUpdateRequestStartAction = (
  id: number,
  form: UserUpdateForm
): BaseAction => ({
  type: types.UPDATE_USER_START,
  payload: {
    id: id,
    form: form,
  },
});

export const userUpdateRequestCompletedAction = (): BaseAction => ({
  type: types.UPDATE_USER_COMPLETED,
  payload: {},
});

export const userUpdateRequestFailedAction = (): BaseAction => ({
  type: types.UPDATE_USER_FAILED,
  payload: {},
});
// ***

export const userBlockStartAction = (id: number): BaseAction => ({
  type: types.BLOCK_USER_START,
  payload: {
    id: id,
  },
});

export const userBlockCompletedAction = (data): BaseAction => ({
  type: types.BLOCK_USER_COMPLETED,
  payload: data,
});

export const userBlockFailedAction = (): BaseAction => ({
  type: types.BLOCK_USER_FAILED,
  payload: null,
});

// ***

export const userUnblockStartAction = (id: number): BaseAction => ({
  type: types.UNBLOCK_USER_START,
  payload: {
    id: id,
  },
});

export const userUnblockCompletedAction = (data): BaseAction => ({
  type: types.UNBLOCK_USER_COMPLETED,
  payload: data,
});

export const userUnblockFailedAction = (): BaseAction => ({
  type: types.UNBLOCK_USER_FAILED,
  payload: null,
});
