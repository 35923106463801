import {
  Backdrop,
  Badge,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControlLabel,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popover,
  Popper,
  Switch,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Device, DeviceFilter } from "../../store/Device/DeviceModel";
import { NewestFirmware, ObsoleteFirmware } from "../../components/bages";
import {
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from "@material-ui/core/styles";

import BlockIcon from "@material-ui/icons/Block";
import DateTime from "../../components/formatters/date-time";
import DeviceProtocol from "../../components/device-protocol";
import DeviceStatus from "../../components/device-status";
import { FileManagerContainer } from "../../components/device-file-manager/fm.container";
import { FileRequestFilter } from "../../store/File/FileModel";
import { FirmwareFlashContainer } from "../../forms/firmware-flash";
import FlashOnIcon from "@material-ui/icons/FlashOn";
import FormatListNumberedIcon from "@material-ui/icons/FormatListNumbered";
import ListIcon from "@material-ui/icons/List";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination } from "../../store/Common/CommonModel";
import { default as React } from "react";
import { Settings } from "../../store/Settings/SettingsModel";
import SubdirectoryArrowLeftIcon from "@material-ui/icons/SubdirectoryArrowLeft";
import SubdirectoryArrowRightIcon from "@material-ui/icons/SubdirectoryArrowRight";
import TableSortHeader from "../../components/table-sort-header";
import { useConfirm } from "material-ui-confirm";
import useFilters from "../../hooks/useFilters";

interface Props {
  onRequestDeviceList: (filter: DeviceFilter) => void;
  onRequestFileRequestItem: (filter: FileRequestFilter) => void;
  onRequestDeviceBlock: (id: number) => void;
  onRequestDeviceUnblock: (id: number) => void;
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) => void;
  requestFilter: FileRequestFilter;
  devicesLoading: boolean;
  devices: Device[];
  pagination: Pagination;
  filter: DeviceFilter;
  settings: Settings;
  firmwareFlashing: boolean;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    blockedSwitch: {
      float: "right",
    },
    table: {
      minWidth: 700,
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    lh24px: {
      lineHeight: "24px",
    },
    blocked: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    test: {
      backgroundColor: lighten(theme.palette.warning.light, 0.75),
    },
    envProd: {
      background: theme.palette.success.light,
      color: "#FFF",
      padding: "4px 10px",
    },
    envTest: {
      background: theme.palette.error.light,
      color: "#FFF",
      padding: "4px 10px",
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const confirm = useConfirm();

  const {
    requestFilter,
    devices,
    devicesLoading,
    filter,
    settings,
    pagination,
    onRequestDeviceList,
    onRequestDeviceBlock,
    onRequestDeviceUnblock,
  } = props;

  // ***

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestDeviceList
  );

  const handleChangeShowBlocked = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    handleChangeValue({ show_blocked: event.target.checked });
  };

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleChangeValue({ page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  /* * */

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  /* Просмотр контроллера */
  /*
    const [openView, setOpenView] = React.useState<Device | false>(false);

    const handleOpenView = (device: Device) => () => {
        setOpenView(device);
    };

    const handleCloseView = () => {
        setOpenView(false);
    };
  */

  /* Просмотр файлов контроллера */

  const [openFiles, setOpenFiles] = React.useState<Device | false>(false);

  const handleOpenFiles = (device: Device) => () => {
    requestFilter.device_id = device.id;
    // onRequestFileRequestItem(device.id);
    setOpenFiles(device);
  };

  const handleCloseFiles = () => {
    setOpenFiles(false);
  };

  /* * */

  const handleBlock = (device: Device) => () => {
    confirm({
      title: "Вы точно хотите заблокировать устройство?",
      description:
        "Сервер будет игнорировать сообщения с заблокированных устройств и отправку команд на них. Так же заблокированные устройства не отображаются в списках устройств.",
    }).then(() => {
      onRequestDeviceBlock(device.id);
    });
  };

  const handleUnblock = (device: Device) => () => {
    onRequestDeviceUnblock(device.id);
  };

  /* * */

  const handleSetTesting = (device: Device, is_test: Boolean) => () => {
    console.log("Testing", is_test, device);
    // confirm({
    //   title: "Вы точно хотите заблокировать устройство?",
    //   description:
    //     "Сервер будет игнорировать сообщения с заблокированных устройств и отправку команд на них. Так же заблокированные устройства не отображаются в списках устройств.",
    // }).then(() => {
    //   onRequestDeviceBlock(device.id);
    // });
  };

  /* Прошивка контроллеров */

  const [openFlash, setOpenFlash] = React.useState<Device>(null);

  const flashForm = {
    devices: [],
    sendCommand: true,
    modbus: 0,
  };

  const handleOpenFlash = (device: Device) => () => {
    flashForm.devices = [];
    flashForm.sendCommand = true;
    flashForm.modbus = 0;

    setOpenFlash(device);
  };

  const handleCloseFlash = () => {
    setOpenFlash(null);
  };

  /* * */

  const getRowStyle = (item: Device) => {
    if (item.is_blocked) {
      return classes.blocked;
    }

    if (item.is_test) {
      return classes.test;
    }

    return null;
  };

  /* * */

  const [anchorBvvEl, setAnchorBvvEl] =
    React.useState<HTMLButtonElement | null>(null);
  const [openBvv, setOpenBvv] = React.useState<null | Number>(null);

  const handleBvvClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setOpenBvv(id);
    setAnchorBvvEl(event.currentTarget);
  };

  const handleBvvClose = () => {
    setOpenBvv(null);
    setAnchorBvvEl(null);
  };

  return (
    <>
      <h1>
        Оборудование
        <FormControlLabel
          className={classes.blockedSwitch}
          control={
            <Switch
              checked={String(newFilter.show_blocked) === "true"}
              onChange={handleChangeShowBlocked}
              name="show_blocked"
              color="primary"
            />
          }
          label="Показывать заблокированные"
        />
      </h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left">
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left">
                <TableSortHeader
                  id="chip_id"
                  title="ID чипа"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="os_type,os_version"
                  title="`Тип.Версия` ОС"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="ppo_type,ppo_version"
                  title="`Тип.Версия` ППО"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">БВВ</TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="protocol"
                  title="Протокол"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left">
                <TableSortHeader
                  id="comment"
                  title="Комментарий"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="is_test"
                  title="Режим"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="created_at"
                  title="Время создания"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center">
                <TableSortHeader
                  id="status"
                  title="Сетевой статус"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" width="180">
                Действия
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {devices &&
              0 < devices.length &&
              devices.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="left">{item.chip_id}</TableCell>

                  <TableCell align="center">
                    <Badge
                      color="secondary"
                      badgeContent={
                        item.need_flash_os_type +
                        "." +
                        item.need_flash_os_version
                      }
                      invisible={item.need_flash_os === 0}
                    >
                      {((item.os_type === 65535 && item.os_version === 65535) ||
                        (item.os_type === 0 && item.os_version === 0)) && (
                        <span className={classes.lh24px}>-</span>
                      )}
                      {item.os_type !== 65535 &&
                        item.os_version !== 65535 &&
                        item.os_type !== 0 &&
                        item.os_version !== 0 && (
                          <>
                            <ObsoleteFirmware
                              defType={item.def_os_type}
                              defVersion={item.def_os_version}
                              currType={item.os_type}
                              currVersion={item.os_version}
                            />
                            <NewestFirmware
                              defType={item.def_os_type}
                              defVersion={item.def_os_version}
                              currType={item.os_type}
                              currVersion={item.os_version}
                            />

                            <span className={classes.lh24px}>
                              {item.os_type}.{item.os_version}
                            </span>
                          </>
                        )}
                    </Badge>
                  </TableCell>

                  <TableCell align="center">
                    <Badge
                      color="secondary"
                      badgeContent={
                        item.need_flash_ppo_type +
                        "." +
                        item.need_flash_ppo_version
                      }
                      invisible={item.need_flash_ppo === 0}
                    >
                      {((item.ppo_type === 65535 &&
                        item.ppo_version === 65535) ||
                        (item.ppo_type === 0 && item.ppo_version === 0)) && (
                        <span className={classes.lh24px}>-</span>
                      )}
                      {item.ppo_type !== 65535 &&
                        item.ppo_version !== 65535 &&
                        item.ppo_type !== 0 &&
                        item.ppo_version !== 0 && (
                          <>
                            <ObsoleteFirmware
                              defType={item.def_ppo_type}
                              defVersion={item.def_ppo_version}
                              currType={item.ppo_type}
                              currVersion={item.ppo_version}
                            />
                            <NewestFirmware
                              defType={item.def_ppo_type}
                              defVersion={item.def_ppo_version}
                              currType={item.ppo_type}
                              currVersion={item.ppo_version}
                            />

                            <span className={classes.lh24px}>
                              {item.ppo_type}.{item.ppo_version}
                            </span>
                          </>
                        )}
                    </Badge>
                  </TableCell>

                  <TableCell align="center">
                    <IconButton
                      aria-describedby={"bvv-" + item.id}
                      onClick={(e) => handleBvvClick(item.id, e)}
                      disabled={Object.keys(item.bvv_config).length < 1}
                    >
                      <FormatListNumberedIcon />
                    </IconButton>

                    <Popover
                      id={"bvv-" + item.id}
                      open={openBvv === item.id}
                      anchorEl={anchorBvvEl}
                      onClose={handleBvvClose}
                      anchorOrigin={{
                        vertical: "bottom",
                        horizontal: "center",
                      }}
                    >
                      <TableContainer>
                        <Table className={classes.table} size="small">
                          <TableHead>
                            <TableRow>
                              <TableCell align="center">#</TableCell>
                              <TableCell align="left">loader</TableCell>
                              <TableCell align="left">ppo</TableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {Object.values(item.bvv_config).map(
                              (bvvItem, id) => (
                                <TableRow>
                                  <TableCell align="center">
                                    {Object.keys(item.bvv_config)[id]}
                                  </TableCell>
                                  <TableCell align="left">
                                    {bvvItem.loader}
                                  </TableCell>
                                  <TableCell align="left">
                                    {bvvItem.ppo}
                                  </TableCell>
                                </TableRow>
                              )
                            )}
                          </TableBody>
                        </Table>
                      </TableContainer>
                    </Popover>
                  </TableCell>

                  <TableCell align="center">
                    <DeviceProtocol value={item.protocol} />
                  </TableCell>

                  <TableCell align="left">{item.comment}</TableCell>

                  <TableCell align="center">
                    {!item.is_test && (
                      <Badge
                        badgeContent="Прод"
                        classes={{ badge: classes.envProd }}
                      />
                    )}
                    {item.is_test && (
                      <Badge
                        badgeContent="Тест"
                        classes={{ badge: classes.envTest }}
                      />
                    )}
                  </TableCell>

                  <TableCell align="center">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.created_at}
                    />
                  </TableCell>
                  <TableCell align="center">
                    <DeviceStatus value={item.status} />
                  </TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      {/* {!item.is_test && (
                        <IconButton
                          color="primary"
                          onClick={handleSetTesting(item, true)}
                        >
                          <Tooltip title="На тест">
                            <SubdirectoryArrowLeftIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                      {item.is_test && (
                        <IconButton
                          color="primary"
                          onClick={handleSetTesting(item, false)}
                        >
                          <Tooltip title="На прод">
                            <SubdirectoryArrowRightIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                      | */}
                      <IconButton
                        color="primary"
                        onClick={handleOpenFlash(item)}
                        disabled={item.is_blocked}
                      >
                        <Tooltip title="Прошить">
                          <FlashOnIcon />
                        </Tooltip>
                      </IconButton>
                      <IconButton
                        onClick={handleOpenFiles(item)}
                        disabled={item.is_blocked}
                      >
                        <Tooltip title="Файлы">
                          <ListIcon />
                        </Tooltip>
                      </IconButton>
                      {!item.is_blocked && (
                        <IconButton
                          color="secondary"
                          onClick={handleBlock(item)}
                        >
                          <Tooltip title="Заблокировать">
                            <BlockIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                      {item.is_blocked && (
                        <IconButton
                          color="primary"
                          onClick={handleUnblock(item)}
                        >
                          <Tooltip title="Разблокировать">
                            <BlockIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          {/* {!item.is_test && (
                            <MenuItem
                              color="primary"
                              onClick={handleSetTesting(item, true)}
                            >
                              На тест
                            </MenuItem>
                          )}
                          {item.is_test && (
                            <MenuItem
                              color="primary"
                              onClick={handleSetTesting(item, false)}
                            >
                              На прод
                            </MenuItem>
                          )}

                          <Divider /> */}

                          <MenuItem
                            color="primary"
                            onClick={handleOpenFlash(item)}
                            disabled={item.is_blocked}
                          >
                            Прошить
                          </MenuItem>

                          <MenuItem
                            onClick={handleOpenFiles(item)}
                            disabled={item.is_blocked}
                          >
                            Файлы
                          </MenuItem>

                          {!item.is_blocked && (
                            <MenuItem onClick={handleBlock(item)}>
                              Заблокировать
                            </MenuItem>
                          )}
                          {item.is_blocked && (
                            <MenuItem onClick={handleUnblock(item)}>
                              Разблокировать
                            </MenuItem>
                          )}
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!devices || devices.length === 0) && (
              <TableRow>
                <TableCell colSpan={11} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.PageCount || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.TotalCount}
          page={pagination.CurrentPage}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseFiles}
        aria-labelledby="customized-dialog-title"
        open={openFiles !== false}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          Файлы на контроллере
        </DialogTitle>
        <DialogContent dividers>
          <FileManagerContainer />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseFiles} color="primary">
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Dialog
        scroll="paper"
        onClose={handleCloseFlash}
        aria-labelledby="customized-dialog-title"
        open={openFlash != null && 0 < openFlash.id}
        maxWidth="md"
        fullWidth={true}
      >
        <FirmwareFlashContainer
          device={openFlash}
          handleCloseFlash={handleCloseFlash}
        />
      </Dialog>

      <Backdrop className={classes.backdrop} open={devicesLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
