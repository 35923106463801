import { BaseAction, Pagination } from "../Common/CommonModel";
import { FileFilter, FileRequest, FileRequestFilter } from "./FileModel";
import * as types from './FileTypes';

export const createFileRequestStartAction = (
    deviceId: number,
    mask: string
): BaseAction =>({
    type: types.CREATE_FILE_REQUEST_START,
    payload: {
        deviceId,
        mask
    },
});

export const createFileRequestCompletedAction = (data): BaseAction => ({
    type: types.CREATE_FILE_REQUEST_COMPLETED,
    payload: data,
});

export const createFileRequestFailedAction = (): BaseAction => ({
    type: types.CREATE_FILE_REQUEST_FAILED,
    payload: null,
});

export const fileRequestStartAction = (
    filter: FileRequestFilter
): BaseAction => ({
    type: types.GET_FILE_REQUEST_LIST_START,
    payload: {
        filter,
    },
});

export const fileRequestCompletedAction = (
    list: FileRequest[],
    pagination: Pagination
): BaseAction => ({
    type: types.GET_FILE_REQUEST_LIST_COMPLETED,
    payload: {
        items: list,
        pagination: pagination,
    },
});

export const fileRequestFailedAction = (): BaseAction => ({
    type: types.GET_FILE_REQUEST_LIST_FAILED,
    payload: {},
});


export const filesStartAction = (
    filter: FileFilter
): BaseAction => ({
    type: types.GET_FILE_LIST_START,
    payload: {
        filter,
    },
});

export const filesCompletedAction = (
    list: File[],
    pagination: Pagination
): BaseAction => ({
    type: types.GET_FILE_LIST_COMPLETED,
    payload: {
        items: list,
        pagination: pagination,
    },
});

export const filesFailedAction = (): BaseAction => ({
    type: types.GET_FILE_LIST_FAILED,
    payload: {},
});

export const downloadFileStartAction = (
    deviceId: number,
    fileId: number
): BaseAction => ({
    type: types.FILE_DOWNLOAD_START,
    payload: {
        deviceId,
        fileId,
    },
});

export const downloadFileCompletedAction = (): BaseAction => ({
    type: types.FILE_DOWNLOAD_FAILED,
    payload: {},
});

export const downloadFileFailedAction = (): BaseAction => ({
    type: types.FILE_DOWNLOAD_COMPLETED,
    payload: {},
});