import * as types from './FileTypes';

import { BaseAction, Pagination } from "../Common/CommonModel";
import { File, FileFilter, FileRequest, FileRequestFilter } from "./FileModel";

export type FileState = {
    listRequest: {
        loading: boolean;
        items: FileRequest[];
        pagination: Pagination;
    };
    listFile: {
        loading: boolean;
        items: File[];
        pagination: Pagination;
    };
    filterRequest: FileRequestFilter;
    filterFile: FileFilter;
};

const defaultFilePagination = {
    CurrentPage: 0,
    PageCount: 0,
    per_page: 1000,
    TotalCount: 0,
};

const defaultFileRequestPagination = {
    CurrentPage: 0,
    PageCount: 0,
    per_page: 25,
    TotalCount: 0,
};

export const defaultFileState: FileState = {
    listRequest: {
        loading: false,
        items: [],
        pagination: defaultFileRequestPagination,
    },
    filterRequest: {
        device_id: 0,
        page: 0,
        per_page: defaultFileRequestPagination.per_page,
        order_by: "created_at",
        order: "desc",
    },
    listFile: {
        loading: false,
        items: [],
        pagination: defaultFilePagination,
    },
    filterFile: {
        request_id: 0,
        page: 0,
        per_page: defaultFilePagination.per_page,
        order_by: "file_name",
        order: "desc",
    },
};

export const fileReducer = (
    state: FileState = defaultFileState,
    action: BaseAction
) => {
    switch (action.type) {
        case types.GET_FILE_REQUEST_LIST_ERASE:
            return defaultFileState;

        case types.GET_FILE_REQUEST_LIST_START:
            return {
                ...state,
                listRequest: {
                    ...state.listRequest,
                    loading: true,
                },
                // listFile: {
                //     loading: false,
                //     items: [],
                //     pagination: defaultFilePagination,
                // },
            };

        case types.GET_FILE_REQUEST_LIST_COMPLETED:
            if (state.filterFile.request_id != action.payload.requestId) {
                return {
                    ...state,
                    listRequest: {
                        loading: false,
                        items: action.payload.items,
                        pagination: action.payload.pagination,
                    },
                };
            } else {
                return state;
            }

        case types.GET_FILE_REQUEST_LIST_FAILED:
            return {
                ...state,
                listRequest: {
                    loading: false,
                    items: [],
                    pagination: defaultFileRequestPagination,
                },
            };

        case types.GET_FILE_LIST_START:
            return {
                ...state,
                listFile: {
                    ...state.listFile,
                    loading: false,
                },
            };

        case types.GET_FILE_LIST_COMPLETED:
            return {
                ...state,
                listFile: {
                    ...state.listFile,
                    loading: false,
                    items: action.payload.items,
                    // pagination: action.payload.pagination,
                },
            };

        case types.GET_FILE_LIST_FAILED:
            return {
                ...state,
                listFile: {
                    loading: false,
                    items: [],
                    pagination: defaultFilePagination,
                },
            };
    }

    return state;
};
