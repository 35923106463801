import * as types from "./UserTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  apiUserBlock,
  apiUserCreate,
  apiUserUnblock,
  apiUserUpdate,
  getUserList,
} from "./UserApis";
import {
  userBlockCompletedAction,
  userBlockFailedAction,
  userCreateRequestCompletedAction,
  userCreateRequestFailedAction,
  userUnblockCompletedAction,
  userUnblockFailedAction,
  userUpdateRequestCompletedAction,
  userUpdateRequestFailedAction,
  usersRequestCompletedAction,
  usersRequestFailedAction,
  usersRequestStartAction,
} from "./UserAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchUserRequestStart() {
  yield takeEvery(types.GET_USER_LIST_START, requestUserList);
  yield takeEvery(types.CREATE_USER_START, requestCreateUser);
  yield takeEvery(types.UPDATE_USER_START, requestUpdateUser);
  yield takeEvery(types.BLOCK_USER_START, requestUserBlock);
  yield takeEvery(types.UNBLOCK_USER_START, requestUserUnBlock);
}

const getProject = (state: State) => state.project.selected.id;
const getFilter = (state: State) => state.user.filter;

function* requestUserList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getUserList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(
      usersRequestCompletedAction(resp.data, {
        CurrentPage: parseInt(resp.headers["x-pagination-current-page"]),
        PageCount: parseInt(resp.headers["x-pagination-page-count"]),
        per_page: parseInt(resp.headers["x-pagination-per-page"]),
        TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
      })
    );
  } catch (e) {
    yield put(usersRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список пользователей",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestCreateUser(action: BaseAction) {
  try {
    const prjId = 0;
    const resp = yield call(apiUserCreate, prjId, action.payload.form);

    if (resp.status !== 200) {
      throw new Error();
    }

    yield put(userCreateRequestCompletedAction());
  } catch (e) {
    yield put(userCreateRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось создать пользователя",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));
}

function* requestUpdateUser(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const resp = yield call(
      apiUserUpdate,
      prjId,
      action.payload.id,
      action.payload.form
    );

    if (resp.status !== 200) {
      throw new Error();
    }

    yield put(userUpdateRequestCompletedAction());
  } catch (e) {
    yield put(userUpdateRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось сохранить пользователя",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));
}

function* requestUserBlock(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiUserBlock, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(userBlockCompletedAction(resp.data));
  } catch (e) {
    yield put(userBlockFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось заблокировать пользователя",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));
}

function* requestUserUnBlock(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiUserUnblock, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(userUnblockCompletedAction(resp.data));

  } catch (e) {
    yield put(userUnblockFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось разблокировать пользователя",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(usersRequestStartAction(filter));  
}
