
export const SELECT_PROJECT = "SELECT_PROJECT - Set project";

export const GET_PROJECT_LIST_START = "GET_PROJECT_LIST - Start";
export const GET_PROJECT_LIST_COMPLETED = "GET_PROJECT_LIST - Completed";
export const GET_PROJECT_LIST_FAILED = "GET_PROJECT_LIST - Failed";

export const GET_PROJECT_DIRECTORY_START = "GET_PROJECT_DIRECTORY - Start";
export const GET_PROJECT_DIRECTORY_COMPLETED = "GET_PROJECT_DIRECTORY - Completed";
export const GET_PROJECT_DIRECTORY_FAILED = "GET_PROJECT_DIRECTORY - Failed";
