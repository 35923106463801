import { AppBar, Tab, Tabs } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { TabContext, TabPanel } from "@material-ui/lab";
import React from "react";
import HexEditor from "react-hex-editor";
import ReactJson from "react-json-view";
import { Event } from "../../store/Event/EventModel";
import { Settings } from "../../store/Settings/SettingsModel";
import DateTime from "../formatters/date-time";
interface Props {
  detailedEvent: Event;
  settings: Settings;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    list: {},
    item: {},
  })
);

export const EventDetailsComponent: React.FunctionComponent<Props> = (
  props
) => {
  const { settings, detailedEvent } = props;
  const classes = useStyles();

  const [value, setValue] = React.useState("data");

  const handleChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    setValue(newValue);
  };

  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="simple tabs example"
        >
          <Tab label="Инфо" value="info" />
          <Tab label="Данные" value="data" />
          <Tab
            label="HEX"
            value="hex"
            disabled={detailedEvent.source == null}
          />
        </Tabs>
      </AppBar>

      <TabContext value={value}>
        <TabPanel value="info">
          <ul className={classes.list}>
            <li className={classes.item}>
              <b>Контроллер:</b> {detailedEvent.device_id}
            </li>
            <li className={classes.item}>
              <b>Время события:</b>{" "}
              <DateTime
                dateFormat={settings.dateFormat}
                timeFormat={settings.timeFormat}
                timeZone={settings.timeZone}
                dateTime={detailedEvent.event_at}
              />
            </li>
            <li className={classes.item}>
              <b>Время получения события:</b>{" "}
              <DateTime
                dateFormat={settings.dateFormat}
                timeFormat={settings.timeFormat}
                timeZone={settings.timeZone}
                dateTime={detailedEvent.created_at}
              />
            </li>
            <li className={classes.item}>
              <b>Будет удалено:</b>{" "}
              <DateTime
                dateFormat={settings.dateFormat}
                timeFormat={settings.timeFormat}
                timeZone={settings.timeZone}
                dateTime={detailedEvent.evicted_at}
              />
            </li>
            <li className={classes.item}>
              <b>Версия выхода: </b>
              {"0x" +
                (detailedEvent.out_version + 0x100000000)
                  .toString(16)
                  .substr(-8)
                  .toUpperCase() +
                " (" +
                detailedEvent.out_version +
                ")"}
            </li>
          </ul>
        </TabPanel>
        <TabPanel value="data">
          <ReactJson
            style={{
              padding: 10,
              border: "1px solid rgba(0, 0, 0, 0.12)",
              borderRadius: 3,
            }}
            // theme="monokai"
            src={detailedEvent.values}
            name={null}
            displayObjectSize={false}
            displayDataTypes={false}
            enableClipboard={false}
            sortKeys={true}
          />
        </TabPanel>
        <TabPanel value="hex">
          <HexEditor
            readOnly={true}
            className="your-hex-editor"
            // columns={0x10} // REQUIRED
            data={detailedEvent.source}
            // height={300} // REQUIRED
            nonce={false}
            rowHeight={22} // REQUIRED
            rows={10} // REQUIRED
            // onSetValue={handleSetValue}
            // width={600} // REQUIRED
          />
        </TabPanel>
      </TabContext>
    </>
  );
};
