import { default as React, useEffect } from "react";
import { Directory } from "../../store/Common/CommonModel";
import { CommandDefault } from "./forms";

interface Props {
  runCommandStartAction: (
    outVersion: number,
    deviceId: number,
    params: string
  ) => void;
  onRequestTemplateDirectory: () => void;
  onRequestDeviceDirectory: () => void;
  commandRunning: boolean;
  deviceDirectory: Directory[];
  templateDirectory: Directory[];
}

const isJsonObject = (str: string) => {
  try {
    JSON.parse(str);
  } catch (e) {
    return false;
  }

  if (typeof JSON.parse(str) != "object") {
    return false;
  }

  return true;
};

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const {
    commandRunning,
    templateDirectory,
    deviceDirectory,
    runCommandStartAction,
    onRequestTemplateDirectory,
    onRequestDeviceDirectory,
  } = props;

  useEffect(() => {
    onRequestTemplateDirectory();
    onRequestDeviceDirectory();
  }, []);

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.ChangeEvent<{}>, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  const handleRunCommand = (
    outVersion: number,
    deviceId: number,
    params: string
  ) => {
    if (!outVersion) {
      alert("Validation Error: `Версия выхода`");
      return;
    }
    if (!deviceId) {
      alert("Validation Error: `Контроллер`");
      return;
    }
    if (!isJsonObject(params)) {
      alert("Validation Error: `Параметры команды`");
      return;
    }

    runCommandStartAction(
      Number(outVersion),
      Number(deviceId),
      JSON.parse(params)
    );
  };

  return (
    <>
      <h1>Команды</h1>

      <div>
        <CommandDefault
          id="default"
          expanded={expanded}
          handleChange={handleChange}
          handleRunCommand={handleRunCommand}
          commandRunning={commandRunning}
          templateDirectory={templateDirectory}
          deviceDirectory={deviceDirectory}
        ></CommandDefault>

        {/* <Command142606336
                    id="142606336"
                    expanded={expanded}
                    handleChange={handleChange}
                ></Command142606336>
                <Command276824064
                    id="276824064"
                    expanded={expanded}
                    handleChange={handleChange}
                ></Command276824064>

                <Command411041792
                    id="411041792"
                    expanded={expanded}
                    handleChange={handleChange}
                ></Command411041792> */}
      </div>
    </>
  );
};
