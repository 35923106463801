import { connect } from "react-redux";
import { projectsRequestStartAction } from "../../store/Project/ProjectAction";
import { ProjectFilter } from "../../store/Project/ProjectModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  projectsLoading: state.project.list.loading,
  projects: state.project.list.items,
  filter: state.project.filter,
  selectedProject: state.project.selected,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestProjectList: (filter: ProjectFilter) =>
    dispatch(projectsRequestStartAction(filter)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
