import { connect } from "react-redux";
import {
  createFileRequestStartAction,
  downloadFileStartAction,
  fileRequestStartAction,
  filesStartAction,
} from "../../store/File/FileAction";
import { FileFilter, FileRequestFilter } from "../../store/File/FileModel";
import { State } from "../../store/reducers";
import { FileManagerComponent } from "./fm.component";

const mapStateToProps = (state: State) => ({
  fileRequestLoading: state.file.listRequest.loading,
  fileRequestPagination: state.file.listRequest.pagination,
  fileRequestFilter: state.file.filterRequest,
  fileRequests: state.file.listRequest.items,
  fileLoading: state.file.listFile.loading,
  filePagination: state.file.listFile.pagination,
  fileFilter: state.file.filterFile,
  files: state.file.listFile.items,
  settings: state.settings,
});

const mapDispatchToProps = (dispatch: any) => ({
  onCreateFileRequestAction: (deviceId: number, mask: string) =>
    dispatch(createFileRequestStartAction(deviceId, mask)),
  onRequestFileRequestItem: (filter: FileRequestFilter) =>
    dispatch(fileRequestStartAction(filter)),
  onFilesRequestList: (filter: FileFilter) =>
    dispatch(filesStartAction(filter)),
  onDownloadFileAction: (deviceId: number, fileId: number) =>
    dispatch(downloadFileStartAction(deviceId, fileId)),
});

export const FileManagerContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(FileManagerComponent);
