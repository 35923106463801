import DateFnsUtils from "@date-io/date-fns";
import { Fade } from "@material-ui/core";
import { ThemeProvider } from "@material-ui/core/styles";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import ruLocale from "date-fns/locale/ru";
import { ConfirmProvider } from "material-ui-confirm";
import "moment-timezone";
import { SnackbarProvider } from "notistack";
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import ApplicationRouter from "./router";
import { store } from "./store";
import theme from "./theme";

const rootElement = document.getElementById("root");

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "center",
        }}
        TransitionComponent={Fade}
        maxSnack={5}
      >
        <MuiPickersUtilsProvider utils={DateFnsUtils} locale={ruLocale}>
          <ConfirmProvider
            defaultOptions={{
              confirmationText: "Да",
              cancellationText: "Нет",
            }}
          >
            <ApplicationRouter />
          </ConfirmProvider>
        </MuiPickersUtilsProvider>
      </SnackbarProvider>
    </ThemeProvider>
  </Provider>,
  rootElement
);
