import { BaseAction, Pagination } from "../Common/CommonModel";
import { EventFilter } from "./EventModel";
import * as types from "./EventTypes";

export const eventListRequestStartAction = (
  filter: EventFilter
): BaseAction => ({
  type: types.GET_EVENT_LIST_START,
  payload: {
    filter,
  },
});

export const eventListRequestCompletedAction = (
  list: Event[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_EVENT_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const eventListRequestFailedAction = (): BaseAction => ({
  type: types.GET_EVENT_LIST_FAILED,
  payload: {},
});

export const eventItemRequestStartAction = (id: number): BaseAction => ({
  type: types.GET_EVENT_ITEM_START,
  payload: {
    id,
  },
});

export const eventItemRequestCompletedAction = (list: Event[]): BaseAction => ({
  type: types.GET_EVENT_ITEM_COMPLETED,
  payload: list,
});

export const eventItemRequestFailedAction = (): BaseAction => ({
  type: types.GET_EVENT_ITEM_FAILED,
  payload: {},
});
