import { BaseAction } from "../Common/CommonModel";
import * as projectTypes from '../Project/ProjectTypes';
import { Settings } from "./SettingsModel";

export type SettingsState = Settings;

export const settingsReducer = (
    state: SettingsState = {
        defaultPage: "/",
        loginPage: "/auth/sign-in",
        selectedProject: 2,
        dateFormat: "YYYY-MM-DD",
        timeFormat: "HH:mm:ss",
        // timeZone: "America/Los_Angeles",
        timeZone: "Asia/Novosibirsk",
    },
    action: BaseAction
) => {
    switch (action.type) {
        case projectTypes.SELECT_PROJECT:
            return {
                ...state,
                selectedProject: action.payload.prj.id,
            };
    }

    return state;
};
