import React from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import { State } from "../store/reducers";
import MainAuthLayout from "./main-auth";

const MainLayout = (props: any) => {
  const { isAuthenticated, loginPage } = props;

  if (!isAuthenticated) {
    return <Redirect to={loginPage} />;
  }

  return <MainAuthLayout {...props} />;
};

const mapStateToProps = (state: State) => ({
  isAuthenticated: state.auth.isAuthenticated,
  loginPage: state.settings.loginPage,
});

export default connect(mapStateToProps)(MainLayout);
