import { Notification } from "../../models";
import { BaseAction } from "../Common/CommonModel";
import * as types from './SnackbarTypes';

export type SnackbarState = {
    notifications: Notification[];
};

export const snackbarReducer = (
    state: SnackbarState = {
        notifications: [],
    },
    action: BaseAction
) => {
    switch (action.type) {
        case types.ENQUEUE_SNACKBAR:
            return {
                ...state,
                notifications: [
                    ...state.notifications,
                    {
                        key: action.payload.key,
                        ...action.payload.notification,
                    },
                ],
            };

        case types.CLOSE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.map((notification) =>
                    action.payload.dismissAll || notification.key === action.payload.key
                        ? { ...notification, dismissed: true }
                        : { ...notification }
                ),
            };

        case types.REMOVE_SNACKBAR:
            return {
                ...state,
                notifications: state.notifications.filter(
                    (notification) => notification.key !== action.payload.key
                ),
            };
    }

    return state;
};
