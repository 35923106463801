import { Badge } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    yes: {
      background: "#00a65a",
      color: "#FFF",
      padding: "4px 10px",
    },
    no: {
      background: "#dd4b39",
      color: "#FFF",
      padding: "4px 10px",
    },
  })
);

const LogicStatus = (props: any) => {
  const classes = useStyles();
  const { value } = props;

  if (value === true) {
    return <Badge badgeContent="Да" classes={{ badge: classes.yes }}></Badge>;
  }

  if (value === false) {
    return <Badge badgeContent="Нет" classes={{ badge: classes.no }}></Badge>;
  }

  return <>-</>;
};

export default LogicStatus;
