import { connect } from "react-redux";
import {
  deviceBlockStartAction,
  devicesRequestStartAction,
  deviceUnblockStartAction,
} from "../../store/Device/DeviceAction";
import { DeviceFilter } from "../../store/Device/DeviceModel";
import { firmwareFlashStartAction } from "../../store/Firmware/FirmwareAction";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  requestFilter: state.file.filterRequest,
  devicesLoading: state.device.list.loading,
  devices: state.device.list.items,
  pagination: state.device.list.pagination,
  filter: state.device.filter,
  settings: state.settings,
  firmwareFlashing: state.firmware.flashing,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestDeviceList: (filter: DeviceFilter) =>
    dispatch(devicesRequestStartAction(filter)),
  onRequestDeviceBlock: (id: number) => dispatch(deviceBlockStartAction(id)),
  onRequestDeviceUnblock: (id: number) =>
    dispatch(deviceUnblockStartAction(id)),
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) =>
    dispatch(
      firmwareFlashStartAction(firmwareId, devices, sendCommand, modbus)
    ),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
