import { Tooltip } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import LastPageIcon from "@material-ui/icons/LastPage";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    tooltip: {
      marginRight: "2px",
    },
    icon: {
      transform: "rotate(-90deg)",
      color: "red",
    },
  })
);

export const ObsoleteFirmware = (props: any) => {
  const classes = useStyles();
  const { defType, defVersion, currType, currVersion } = props;

  return (
    <>
      {(defType > currType ||
        (defType == currType && defVersion > currVersion)) && (
        <Tooltip
          title={"Прошивка устарела. Дефолтная: " + defType + "." + defVersion}
          className={classes.tooltip}
        >
          <LastPageIcon className={classes.icon} />
        </Tooltip>
      )}
    </>
  );
};
