import { BaseAction, Directory } from "../Common/CommonModel";
import { Project, ProjectFilter } from "./ProjectModel";
import * as types from './ProjectTypes';

export const selectProject = (
    prj: Directory
): BaseAction => ({
    type: types.SELECT_PROJECT,
    payload: {
        prj,
    },
});

export const projectsRequestStartAction = (
    filter: ProjectFilter
): BaseAction => ({
    type: types.GET_PROJECT_LIST_START,
    payload: {
        filter,
    },
});

export const projectsRequestCompletedAction = (
    list: Project[]
): BaseAction => ({
    type: types.GET_PROJECT_LIST_COMPLETED,
    payload: list,
});

export const projectsRequestFailedAction = (): BaseAction => ({
    type: types.GET_PROJECT_LIST_FAILED,
    payload: {},
});

export const projectDirectoryRequestStartAction = (): BaseAction => ({
    type: types.GET_PROJECT_DIRECTORY_START,
    payload: {},
});

export const projectDirectoryRequestCompletedAction = (
    list: Directory[]
): BaseAction => ({
    type: types.GET_PROJECT_DIRECTORY_COMPLETED,
    payload: {
        items: list,
    },
});

export const projectDirectoryRequestFailedAction = (): BaseAction => ({
    type: types.GET_PROJECT_DIRECTORY_FAILED,
    payload: {},
});


