import { CssBaseline } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { HeaderContainer } from "../components/header-auth/header.container";
import { NavContainer } from "../components/nav/nav.container";
import Notifier from "../components/notifier";

interface Props {}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      height: "100vh",
      flexGrow: 1,
      padding: 0,
      width: "100%",
    },
    appBarSpacer: theme.mixins.toolbar,
    container: {
      padding: theme.spacing(3),
    },
  })
);

const MainAuthLayout: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <Notifier />
      <CssBaseline />
      <HeaderContainer />
      <NavContainer />

      <main className={classes.root}>
        <div className={classes.appBarSpacer} />

        <div className={classes.container}>{props.children}</div>
      </main>
    </>
  );
};

export default MainAuthLayout;
