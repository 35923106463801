import { BaseAction } from "../Common/CommonModel";
import * as commontypes from "../Common/CommonTypes";
import * as types from "./AuthTypes";

export type AuthState = {
  isAdmin: boolean;
  isAuthenticated: boolean;
  accessToken: string;
  loading: boolean;
};

export const defaultAuthState: AuthState = {
  isAdmin: false,
  isAuthenticated: false,
  accessToken: "",
  loading: false,
};

export const authReducer = (
  state: AuthState = defaultAuthState,
  action: BaseAction
) => {
  switch (action.type) {
    case commontypes.API_ERROR_401:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        accessToken: "",
      };

    case types.SIGN_OUT:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        accessToken: "",
      };

    case types.SIGN_IN_START:
      return {
        ...state,
        loading: true,
      };

    case types.SIGN_IN_COMPLETED:
      return {
        ...state,
        isAdmin: action.payload.isAdmin,
        loading: false,
        isAuthenticated: true,
        accessToken: action.payload.token,
      };

    case types.SIGN_IN_FAILED:
      return {
        ...state,
        loading: false,
        isAuthenticated: false,
        accessToken: "",
      };
  }

  return state;
};
