import * as types from "./DeviceTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  apiDeviceBlock,
  apiDeviceUnblock,
  getDeviceDirectory,
  getDeviceList,
} from "./DeviceApis";
import {
  deviceBlockCompletedAction,
  deviceBlockFailedAction,
  deviceDirectoryRequestCompletedAction,
  deviceDirectoryRequestFailedAction,
  deviceRequestFailedAction,
  deviceUnblockCompletedAction,
  deviceUnblockFailedAction,
  devicesRequestCompletedAction,
  devicesRequestStartAction,
} from "./DeviceAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchDeviceRequestStart() {
  yield takeEvery(types.GET_DEVICE_LIST_START, requestDeviceList);
  yield takeEvery(types.GET_DEVICE_DIRECTORY_START, requestDeviceDirectory);
  yield takeEvery(types.BLOCK_DEVICE_START, requestDeviceBlock);
  yield takeEvery(types.UNBLOCK_DEVICE_START, requestDeviceUnBlock);
}

const getFilter = (state: State) => state.device.filter;
const getProject = (state: State) => state.project.selected.id;

function* requestDeviceList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getDeviceList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }
        
    if (resp.data == null) {
      resp.data = [];
    }

    yield put(
      devicesRequestCompletedAction(resp.data, {
        CurrentPage: parseInt(resp.headers["x-pagination-current-page"]),
        PageCount: parseInt(resp.headers["x-pagination-page-count"]),
        per_page: parseInt(resp.headers["x-pagination-per-page"]),
        TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
      })
    );
  } catch (e) {
    yield put(deviceRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список устройств",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestDeviceDirectory(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getDeviceDirectory, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }
        
    if (resp.data == null) {
      resp.data = [];
    }

    yield put(deviceDirectoryRequestCompletedAction(resp.data));
  } catch (e) {
    yield put(deviceDirectoryRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список устройств",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestDeviceBlock(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiDeviceBlock, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(deviceBlockCompletedAction(resp.data));
  } catch (e) {
    yield put(deviceBlockFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось заблокировать устройство",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(devicesRequestStartAction(filter));
}

function* requestDeviceUnBlock(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiDeviceUnblock, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }
     
    yield put(deviceUnblockCompletedAction(resp.data));
  } catch (e) {
    yield put(deviceUnblockFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось разблокировать устройство",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(devicesRequestStartAction(filter));
}
