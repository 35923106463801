import {
    BaseAction, Directory,
    Pagination
} from "../Common/CommonModel";
import {
    Firmware,
    FirmwareFilter
} from "./FirmwareModel";
import * as types from './FirmwareTypes';

export const firmwaresRequestStartAction = (
    filter: FirmwareFilter
): BaseAction => ({
    type: types.GET_FIRMWARE_LIST_START,
    payload: {
        filter,
    },
});

export const firmwaresRequestCompletedAction = (
    list: Firmware[],
    pagination: Pagination
): BaseAction => ({
    type: types.GET_FIRMWARE_LIST_COMPLETED,
    payload: {
        items: list,
        pagination: pagination,
    },
});

export const firmwaresRequestFailedAction = (): BaseAction => ({
    type: types.GET_FIRMWARE_LIST_FAILED,
    payload: {},
});

export const firmwareDirectoryRequestStartAction = (): BaseAction => ({
    type: types.GET_FIRMWARE_DIRECTORY_START,
    payload: {},
});

export const firmwareDirectoryRequestCompletedAction = (
    list: Directory[]
): BaseAction => ({
    type: types.GET_FIRMWARE_DIRECTORY_COMPLETED,
    payload: {
        items: list,
    },
});

export const firmwareDirectoryRequestFailedAction = (): BaseAction => ({
    type: types.GET_FIRMWARE_DIRECTORY_FAILED,
    payload: {},
});

export const firmwareCreateStartAction = (formData: FormData): BaseAction => ({
    type: types.CREATE_FIRMWARE_START,
    payload: {
        formData: formData,
    },
});

export const firmwareCreateCompletedAction = (data): BaseAction => ({
    type: types.CREATE_FIRMWARE_COMPLETED,
    payload: data,
});

export const firmwareCreateFailedAction = (): BaseAction => ({
    type: types.CREATE_FIRMWARE_FAILED,
    payload: null,
});

export const firmwareDeleteStartAction = (id: number): BaseAction => ({
    type: types.DELETE_FIRMWARE_START,
    payload: {
        id: id,
    },
});

export const firmwareDeleteCompletedAction = (data): BaseAction => ({
    type: types.DELETE_FIRMWARE_COMPLETED,
    payload: data,
});

export const firmwareDeleteFailedAction = (): BaseAction => ({
    type: types.DELETE_FIRMWARE_FAILED,
    payload: null,
});


export const firmwareSetDefaultStartAction = (id: number): BaseAction => ({
    type: types.SET_DEFAULT_FIRMWARE_START,
    payload: {
        id: id,
    },
});

export const firmwareSetDefaultCompletedAction = (data): BaseAction => ({
    type: types.SET_DEFAULT_FIRMWARE_COMPLETED,
    payload: data,
});

export const firmwareSetDefaultFailedAction = (): BaseAction => ({
    type: types.SET_DEFAULT_FIRMWARE_FAILED,
    payload: null,
});

export const firmwareFlashStartAction = (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
): BaseAction => ({
    type: types.FLASH_FIRMWARE_START,
    payload: {
        firmwareId,
        devices,
        sendCommand,
        modbus,
    },
});

export const firmwareFlashCompletedAction = (data): BaseAction => ({
    type: types.FLASH_FIRMWARE_COMPLETED,
    payload: data,
});

export const firmwareFlashFailedAction = (): BaseAction => ({
    type: types.FLASH_FIRMWARE_FAILED,
    payload: null,
});
