import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { templateUpdateRequestStartAction } from "../../store/Template/TemplateAction";
import { TemplateUpdateForm } from "../../store/Template/TemplateModel";
import { TemplateUpdateComponent } from "./update.component";

const mapStateToProps = (state: State) => ({
  templateUpdating: state.template.updating,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestTemplateUpdate: (id: number, form: TemplateUpdateForm) =>
    dispatch(templateUpdateRequestStartAction(id, form)),
});

export const TemplateUpdateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateUpdateComponent);
