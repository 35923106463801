import { BaseAction } from "../Common/CommonModel";
import * as types from './CommandTypes';

export const runCommandStartAction = (
    outVersion: number,
    deviceId: number,
    params: string
): BaseAction => ({
    type: types.RUN_COMMAND_START,
    payload: {
        outVersion,
        deviceId,
        params,
    },
});

export const runCommandCompletedAction = (data): BaseAction => ({
    type: types.RUN_COMMAND_COMPLETED,
    payload: data,
});

export const runCommandFailedAction = (): BaseAction => ({
    type: types.RUN_COMMAND_FAILED,
    payload: null,
});
