import { Button, Chip, TextField } from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: "flex",
    },
    uploadInput: {
      display: "none",
    },
    fileName: {
      marginRight: theme.spacing(2),
      flex: 1,
    },
    fileSize: {
      marginRight: theme.spacing(2),
      marginTop: 2,
    },
    button: {},
  })
);

const prettyFileSize = (bytes: number) => {
  return bytes + " B";
};

const InputFile = (props: any) => {
  const classes = useStyles();
  const { onChange } = props;

  const [fileName, setFileName] = React.useState("Выберите файл");
  const [fileSize, setFileSize] = React.useState(0);

  const handleChangeFile = (event: any) => {
    if (0 < event.target.files.length) {
      setFileName(event.target.files[0].name);
      setFileSize(event.target.files[0].size);
      onChange(event.target.files[0]);
    } else {
      setFileName("Выберите файл");
      setFileSize(0);
      onChange(null);
    }
  };

  return (
    <label htmlFor="contained-button-file">
      <input
        accept=".plc,.app,.xfw"
        className={classes.uploadInput}
        id="contained-button-file"
        multiple
        type="file"
        onChange={handleChangeFile}
      />

      <div className={classes.root}>
        <TextField
          id="standard-basic"
          className={classes.fileName}
          value={fileName}
          disabled
        />
        {0 < fileSize && (
          <Chip
            label={prettyFileSize(fileSize)}
            variant="outlined"
            color="secondary"
            className={classes.fileSize}
          />
        )}

        <Button
          variant="contained"
          color="primary"
          component="span"
          className={classes.button}
        >
          Выбрать
        </Button>
      </div>
    </label>
  );
};

export default InputFile;
