import * as types from "./FirmwareTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import {
  apiFirmwareDelete,
  apiFirmwareSetDefault,
  getFirmwareDirectory,
  getFirmwareList,
  postFirmwareCreate,
  postFirmwareFlash,
} from "./FirmwareApis";
import {
  firmwareCreateCompletedAction,
  firmwareCreateFailedAction,
  firmwareDeleteCompletedAction,
  firmwareDeleteFailedAction,
  firmwareDirectoryRequestCompletedAction,
  firmwareDirectoryRequestFailedAction,
  firmwareFlashCompletedAction,
  firmwareFlashFailedAction,
  firmwareSetDefaultCompletedAction,
  firmwareSetDefaultFailedAction,
  firmwaresRequestCompletedAction,
  firmwaresRequestFailedAction,
  firmwaresRequestStartAction,
} from "./FirmwareAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { devicesRequestStartAction } from "../Device/DeviceAction";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchFirmwareRequestStart() {
  yield takeEvery(types.GET_FIRMWARE_LIST_START, requestFirmwareList);
  yield takeEvery(types.GET_FIRMWARE_DIRECTORY_START, requestFirmwareDirectory);
  yield takeEvery(types.CREATE_FIRMWARE_START, requestFirmwareCreate);
  yield takeEvery(types.DELETE_FIRMWARE_START, requestFirmwareDelete);
  yield takeEvery(types.SET_DEFAULT_FIRMWARE_START, requestFirmwareSetDefault);
  yield takeEvery(types.FLASH_FIRMWARE_START, requestFirmwareFlash);
  yield takeEvery(types.GET_FIRMWARE_ITEM_START, requestFirmwareItem);
}

const getFilter = (state: State) => state.firmware.filter;
const getPage = (state: State) => state.nav.pagePath;
const getProject = (state: State) => state.project.selected.id;
const getDeviceFilter = (state: State) => state.device.filter;

function* requestFirmwareList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getFirmwareList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }
    
    if (resp.data == null) {
      resp.data = [];
    }

    yield put(
      firmwaresRequestCompletedAction(resp.data, {
        CurrentPage: parseInt(resp.headers["x-pagination-current-page"]),
        PageCount: parseInt(resp.headers["x-pagination-page-count"]),
        per_page: parseInt(resp.headers["x-pagination-per-page"]),
        TotalCount: parseInt(resp.headers["x-pagination-total-count"]),
      })
    );
  } catch (e) {
    yield put(firmwaresRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список прошивок",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestFirmwareDirectory(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getFirmwareDirectory, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(firmwareDirectoryRequestCompletedAction(resp.data));
  } catch (e) {
    yield put(firmwareDirectoryRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список прошивок",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestFirmwareItem(action: BaseAction) {}

function* requestFirmwareCreate(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(postFirmwareCreate, prjId, action.payload),
    });

    if (resp.status !== 201) {
      throw new Error();
    }

    yield put(firmwareCreateCompletedAction(resp.data));
  } catch (e) {
    yield put(firmwareCreateFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось создать прошивку",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(firmwaresRequestStartAction(filter));
}

function* requestFirmwareDelete(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiFirmwareDelete, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(firmwareDeleteCompletedAction(resp.data));
  } catch (e) {
    yield put(firmwareDeleteFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось удалить прошивку. Возможно эту прошивку нельзя удалить, например если какой-то контроллер ожидает ее для прошивки",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(firmwaresRequestStartAction(filter));
}

function* requestFirmwareSetDefault(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(apiFirmwareSetDefault, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    yield put(firmwareSetDefaultCompletedAction(resp.data));
  } catch (e) {
    yield put(firmwareSetDefaultFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось сделать прошивку `дефолтной`",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const filter = yield select(getFilter);
  yield put(firmwaresRequestStartAction(filter));
}

function* requestFirmwareFlash(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(postFirmwareFlash, prjId, action.payload),
    });

    if (resp.status !== 202) {
      throw new Error();
    }

    yield put(firmwareFlashCompletedAction(resp));

    const errorNotification = creatingNotification(
      "Команда для прошивки отправлена на контроллер(ы)",
      "success"
    );
    yield put(enqueueSnackbar(errorNotification));
  } catch (e) {
    yield put(firmwareFlashFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось отправить команду для прошивки на контроллер(ы)",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }

  const currPage = yield select(getPage);
  if (currPage === "/devices") {
    const deviceFilter = yield select(getDeviceFilter);
    yield put(devicesRequestStartAction(deviceFilter));
  }
}
