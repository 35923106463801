import { connect } from "react-redux";
import { State } from "../../store/reducers";
import { templateCreateRequestStartAction } from "../../store/Template/TemplateAction";
import { TemplateCreateForm } from "../../store/Template/TemplateModel";
import { TemplateCreateComponent } from "./create.component";

const mapStateToProps = (state: State) => ({
  templateUpdating: state.template.updating,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestTemplateCreate: (form: TemplateCreateForm) =>
    dispatch(templateCreateRequestStartAction(form)),
});

export const TemplateCreateContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(TemplateCreateComponent);
