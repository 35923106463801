import Accordion from "@material-ui/core/Accordion";
import AccordionDetails from "@material-ui/core/AccordionDetails";
import AccordionSummary from "@material-ui/core/AccordionSummary";
import Box from "@material-ui/core/Box";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogContentText from "@material-ui/core/DialogContentText";
import DialogTitle from "@material-ui/core/DialogTitle";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import TextField from "@material-ui/core/TextField";
import Typography from "@material-ui/core/Typography";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        root: {
            width: "100%",
        },
        runButton: {
            marginRight: 10,
        },
        commandTitle: {
            fontSize: theme.typography.pxToRem(16),
            lineHeight: theme.typography.pxToRem(30),
        },
        commandContent: {
            borderTop: "1px solid rgba(0, 0, 0, 0.12);",
            padding: "5px 16px",
        },
    })
);

export function Command411041792(props) {
    const classes = useStyles();
    const { id, expanded, handleChange } = props;

    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <Accordion
            expanded={expanded === id}
            TransitionProps={{ unmountOnExit: true }}
            onChange={handleChange(id)}
        >
            <AccordionSummary
                expandIcon={<ExpandMoreIcon />}
                aria-label="Expand"
                aria-controls={"#" + id + "-111"}
                id={"command-" + id}
            >
                <Button
                    className={classes.runButton}
                    variant="contained"
                    color="primary"
                    size="small"
                    onClick={handleClickOpen}
                >
                    Run
                </Button>
                <Dialog
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="form-dialog-title"
                >
                    <DialogTitle id="customized-dialog-title">
                        Modal title
                    </DialogTitle>
                    <DialogContent dividers>
                        <DialogContentText>
                            To subscribe to this website, please enter your
                            email address here. We will send updates
                            occasionally.
                        </DialogContentText>
                        <TextField
                            autoFocus
                            margin="dense"
                            id="name"
                            label="Email Address"
                            type="email"
                            fullWidth
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose} color="primary">
                            Cancel
                        </Button>
                        <Button onClick={handleClose} color="primary">
                            Run
                        </Button>
                    </DialogActions>
                </Dialog>

                <Typography className={classes.commandTitle}>
                    <b>0x18800000</b> - Установить время отключения
                </Typography>
            </AccordionSummary>
            <AccordionDetails className={classes.commandContent}>
                <Box>Тут описние параметров команды</Box>
            </AccordionDetails>
        </Accordion>
    );
}
