export const GET_USER_LIST_START = "GET_USER_LIST - Start";
export const GET_USER_LIST_COMPLETED = "GET_USER_LIST - Completed";
export const GET_USER_LIST_FAILED = "GET_USER_LIST - Failed";

export const CREATE_USER_START = "CREATE_USER - Start";
export const CREATE_USER_COMPLETED = "CREATE_USER - Completed";
export const CREATE_USER_FAILED = "CREATE_USER - Failed";

export const UPDATE_USER_START = "UPDATE_USER - Start";
export const UPDATE_USER_COMPLETED = "UPDATE_USER - Completed";
export const UPDATE_USER_FAILED = "UPDATE_USER - Failed";

export const BLOCK_USER_START = "BLOCK_USER - Start";
export const BLOCK_USER_COMPLETED = "BLOCK_USER - Completed";
export const BLOCK_USER_FAILED = "BLOCK_USER - Failed";
export const UNBLOCK_USER_START = "UNBLOCK_USER - Start";
export const UNBLOCK_USER_COMPLETED = "UNBLOCK_USER - Completed";
export const UNBLOCK_USER_FAILED = "UNBLOCK_USER - Failed";
