import { connect } from "react-redux";
import { signInAction } from "../../store/Auth/AuthAction";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  isAuthenticated: state.auth.isAuthenticated,
  defaultPage: state.settings.defaultPage,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestSignIn: (email: string, password: string) =>
    dispatch(signInAction(email, password)),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
