import * as types from './AuthTypes';

import { all, call, put, takeEvery } from "redux-saga/effects";
import { signInCompletedAction, signInFailedAction } from "./AuthAction";

import { BaseAction } from "../Common/CommonModel";
import { apiSignIn } from "./AuthApis";

import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchAuthStart() {
  yield takeEvery(types.SIGN_IN_START, signIn);
}

function* signIn(action: BaseAction) {
  try {
      const { resp } = yield all({
          resp: call(apiSignIn, action.payload),
      });
      
      if (resp.status !== 200) {
          throw new Error();
      }

      yield put(signInCompletedAction(resp.data.token, resp.data.is_admin));
  } catch (e) {
      yield put(signInFailedAction());

      const errorNotification = creatingNotification(
          "Проверьте правильность ввода логина/пароля.",
          "error"
      );
      yield put(enqueueSnackbar(errorNotification));
  }
}
