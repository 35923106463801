import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Device, DeviceFilter } from "./DeviceModel";
import * as types from "./DeviceTypes";

export const devicesRequestStartAction = (
  filter: DeviceFilter
): BaseAction => ({
  type: types.GET_DEVICE_LIST_START,
  payload: {
    filter,
  },
});

export const devicesRequestCompletedAction = (
  list: Device[],
  pagination: Pagination
): BaseAction => ({
  type: types.GET_DEVICE_LIST_COMPLETED,
  payload: {
    items: list,
    pagination: pagination,
  },
});

export const deviceRequestFailedAction = (): BaseAction => ({
  type: types.GET_DEVICE_LIST_FAILED,
  payload: {},
});

export const deviceDirectoryRequestStartAction = (): BaseAction => ({
  type: types.GET_DEVICE_DIRECTORY_START,
  payload: {},
});

export const deviceDirectoryRequestCompletedAction = (
  list: Directory[]
): BaseAction => ({
  type: types.GET_DEVICE_DIRECTORY_COMPLETED,
  payload: {
    items: list,
  },
});

export const deviceDirectoryRequestFailedAction = (): BaseAction => ({
  type: types.GET_DEVICE_DIRECTORY_FAILED,
  payload: {},
});

// ***

export const deviceBlockStartAction = (id: number): BaseAction => ({
  type: types.BLOCK_DEVICE_START,
  payload: {
    id: id,
  },
});

export const deviceBlockCompletedAction = (data): BaseAction => ({
  type: types.BLOCK_DEVICE_COMPLETED,
  payload: data,
});

export const deviceBlockFailedAction = (): BaseAction => ({
  type: types.BLOCK_DEVICE_FAILED,
  payload: null,
});

// ***

export const deviceUnblockStartAction = (id: number): BaseAction => ({
  type: types.UNBLOCK_DEVICE_START,
  payload: {
    id: id,
  },
});

export const deviceUnblockCompletedAction = (data): BaseAction => ({
  type: types.UNBLOCK_DEVICE_COMPLETED,
  payload: data,
});

export const deviceUnblockFailedAction = (): BaseAction => ({
  type: types.UNBLOCK_DEVICE_FAILED,
  payload: null,
});
