import agentInstance from "../../api/agent";
import { Event } from "./EventModel";

export const getEventList = (prjId: Number, params: {}): Promise<Event[]> => {
    return new Promise<Event[]>((resolve) => {
        resolve(
            agentInstance(prjId).get(`events/`, {
                params: params["filter"],
            })
        );
    });
};

export const getEventItem = (prjId: Number, params: {}): Promise<Event[]> => {
    return new Promise<Event[]>((resolve) => {
        resolve(agentInstance(prjId).get(`events/` + params["id"] + `/`));
    });
};
