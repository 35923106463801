import { Notification } from "../../models";
import * as types from './SnackbarTypes';

export const enqueueSnackbar = (notification: Notification) => {
    const key = notification.options && notification.options.key;

    return {
        type: types.ENQUEUE_SNACKBAR,
        payload: {
            notification: {
                ...notification,
                key: key || new Date().getTime() + Math.random(),
            },
        },
    };
};

export const closeSnackbar = (key) => ({
    type: types.CLOSE_SNACKBAR,
    payload: {
        dismissAll: !key, // dismiss all if no key has been defined
        key,
    },
});

export const removeSnackbar = (key) => ({
    type: types.REMOVE_SNACKBAR,
    payload: {
        key,
    },
});
