import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      textTransform: "none",
    },
  })
);

const OutVersion = (props: any) => {
  const classes = useStyles();
  const { value } = props;

  return (
    <span className={classes.root}>
      {"0x" + (value + 0x100000000).toString(16).substr(-8).toUpperCase()}
    </span>
  );
};

export default OutVersion;
