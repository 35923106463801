import { connect } from "react-redux";
import { deviceDirectoryRequestStartAction } from "../../store/Device/DeviceAction";
import {
  firmwareCreateStartAction,
  firmwareDeleteStartAction,
  firmwareFlashStartAction,
  firmwareSetDefaultStartAction,
  firmwaresRequestStartAction,
} from "../../store/Firmware/FirmwareAction";
import { FirmwareFilter } from "../../store/Firmware/FirmwareModel";
import { State } from "../../store/reducers";
import { PageComponent } from "./page.component";

const mapStateToProps = (state: State) => ({
  firmwaresLoading: state.firmware.list.loading,
  firmwares: state.firmware.list.items,
  firmwareUploading: state.firmware.uploading,
  firmwareFlashing: state.firmware.flashing,
  pagination: state.firmware.list.pagination,
  filter: state.firmware.filter,
  settings: state.settings,
  deviceDirectory: state.device.directory.items,
});

const mapDispatchToProps = (dispatch: any) => ({
  onRequestFirmwareList: (filter: FirmwareFilter) =>
    dispatch(firmwaresRequestStartAction(filter)),
  onRequestFirmwareCreate: (data: FormData) =>
    dispatch(firmwareCreateStartAction(data)),
  onRequestFirmwareDelete: (id: number) =>
    dispatch(firmwareDeleteStartAction(id)),
  onRequestFirmwareSetDefault: (id: number) =>
    dispatch(firmwareSetDefaultStartAction(id)),
  onRequestFirmwareFlash: (
    firmwareId: number,
    devices: [],
    sendCommand: boolean,
    modbus: number
  ) =>
    dispatch(
      firmwareFlashStartAction(firmwareId, devices, sendCommand, modbus)
    ),
  onRequestDeviceDirectory: () => dispatch(deviceDirectoryRequestStartAction()),
});

export const PageContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(PageComponent);
