import * as types from "./FirmwareTypes";

import { BaseAction, Directory, Pagination } from "../Common/CommonModel";
import { Firmware, FirmwareFilter } from "./FirmwareModel";

export type FirmwareState = {
  list: {
    loading: boolean;
    items: Firmware[];
    pagination: Pagination;
  };
  filter: FirmwareFilter;
  uploading: boolean;
  flashing: boolean;
  directory: {
    loading: boolean;
    items: Directory[];
  };
};

const defaultFirmwarePagination = {
  CurrentPage: 0,
  PageCount: 0,
  per_page: 25,
  TotalCount: 0,
};

export const defaultFirmwareState: FirmwareState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultFirmwarePagination,
  },
  directory: {
    loading: false,
    items: [],
  },
  filter: {
    page: 0,
    per_page: defaultFirmwarePagination.per_page,
    order_by: "id",
    order: "desc",
  },
  flashing: false,
  uploading: false,
};

export const firmwareReducer = (
  state: FirmwareState = defaultFirmwareState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_FIRMWARE_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_FIRMWARE_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_FIRMWARE_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultFirmwarePagination,
        },
      };

    case types.GET_FIRMWARE_DIRECTORY_START:
      return {
        ...state,
        directory: {
          items: [],
          loading: true,
        },
      };

    case types.GET_FIRMWARE_DIRECTORY_COMPLETED:
      return {
        ...state,
        directory: {
          loading: false,
          items: action.payload.items,
        },
      };

    case types.GET_FIRMWARE_DIRECTORY_FAILED:
      return {
        ...state,
        directory: {
          loading: false,
          items: [],
        },
      };

    case types.CREATE_FIRMWARE_START:
      return {
        ...state,
        uploading: true,
      };

    case types.CREATE_FIRMWARE_COMPLETED:
      return {
        ...state,
        uploading: false,
      };

    case types.CREATE_FIRMWARE_FAILED:
      return {
        ...state,
        uploading: false,
      };

    case types.FLASH_FIRMWARE_START:
      return {
        ...state,
        flashing: true,
      };

    case types.FLASH_FIRMWARE_COMPLETED:
      return {
        ...state,
        flashing: false,
      };

    case types.FLASH_FIRMWARE_FAILED:
      return {
        ...state,
        flashing: false,
      };
  }

  return state;
};
