import { all, fork } from "redux-saga/effects";
import { watchAuthStart } from "./Auth/AuthSagas";
import { watchRunCommandStart } from "./Command/CommandSagas";
import { watchDeviceRequestStart } from "./Device/DeviceSagas";
import { watchEventRequestStart } from "./Event/EventSagas";
import { watchFileRequestStart } from "./File/FileSagas";
import { watchFirmwareRequestStart } from "./Firmware/FirmwareSagas";
import { watchProjectRequestStart } from "./Project/ProjectSagas";
import { watchTemplateRequestStart } from "./Template/TemplateSagas";
import { watchUserRequestStart } from "./User/UserSagas";

export const rootSaga = function* root() {
    yield all([
        fork(watchAuthStart),
        fork(watchRunCommandStart),
        fork(watchDeviceRequestStart),
        fork(watchEventRequestStart),
        fork(watchProjectRequestStart),
        fork(watchTemplateRequestStart),
        fork(watchFirmwareRequestStart),
        fork(watchFileRequestStart),
        fork(watchUserRequestStart),
    ]);
};
