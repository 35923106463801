import {
  Backdrop,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Directory, Pagination } from "../../store/Common/CommonModel";
import { Event, EventFilter } from "../../store/Event/EventModel";
import React, { useEffect } from "react";
import {
  Theme,
  createStyles,
  lighten,
  makeStyles,
  useTheme,
} from "@material-ui/core/styles";

import { DateFilter } from "../../components/filters/date-filter";
import DateTime from "../../components/formatters/date-time";
import { DeviceFilter } from "../../components/filters/device-filter";
import { EventDetailsContainer } from "../../components/event-details/details.container";
import KeyboardArrowLeft from "@material-ui/icons/KeyboardArrowLeft";
import KeyboardArrowRight from "@material-ui/icons/KeyboardArrowRight";
import OutVersion from "../../components/formatters/out-version";
import { OutVersionFilter } from "../../components/filters/out-version-filter";
import PropTypes from "prop-types";
import { Settings } from "../../store/Settings/SettingsModel";
import TableSortHeader from "../../components/table-sort-header";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useFilters from "../../hooks/useFilters";

interface Props {
  onRequestEventList: (filter: EventFilter) => void;
  onRequestEventItem: (id: number) => void;
  onRequestDeviceDirectory: () => void;
  onRequestTemplateDirectory: () => void;
  eventsLoading: boolean;
  events: Event[];
  pagination: Pagination;
  filter: EventFilter;
  detailedLoading: boolean;
  detailedEvent: Event;
  settings: Settings;
  deviceDirectory: Directory[];
  templateDirectory: Directory[];
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 25,
      verticalAlign: "top",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    debug: {},
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
  })
);

const useStyles1 = makeStyles((theme) => ({
  root: {
    flexShrink: 0,
    marginLeft: theme.spacing(2.5),
  },
}));

function TablePaginationActions(props) {
  const classes = useStyles1();
  const theme = useTheme();
  const { count, page, rowsPerPage, onPageChange } = props;

  const handleBackButtonClick = (event) => {
    onPageChange(event, page - 1);
  };

  const handleNextButtonClick = (event) => {
    onPageChange(event, page + 1);
  };

  const handleSetPage = (event) => {
    onPageChange(event, event.target.value as number);
  };

  var countPage = (count - (count % rowsPerPage)) / rowsPerPage,
    minPage = Math.max(0, page - 200),
    maxPage = Math.min(page + 200, countPage);

  var rows = [],
    i = minPage;
  while (++i <= maxPage) rows.push(i);

  return (
    <div className={classes.root}>
      {/* <p>Page:</p> */}
      <span>Page:&nbsp;&nbsp;</span>
      <Select
        labelId="demo-controlled-open-select-label"
        id="demo-controlled-open-select"
        value={page}
        onChange={handleSetPage}
      >
        {1 < minPage && <MenuItem disabled>...</MenuItem>}

        {rows.map(function (i) {
          return (
            <MenuItem key={i - 1} value={i - 1}>
              {i}
            </MenuItem>
          );
        })}

        {maxPage < countPage && <MenuItem disabled>...</MenuItem>}
      </Select>
      <IconButton
        onClick={handleBackButtonClick}
        disabled={page === 0}
        aria-label="previous page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowRight />
        ) : (
          <KeyboardArrowLeft />
        )}
      </IconButton>
      <IconButton
        onClick={handleNextButtonClick}
        disabled={page >= Math.ceil(count / rowsPerPage) - 1}
        aria-label="next page"
      >
        {theme.direction === "rtl" ? (
          <KeyboardArrowLeft />
        ) : (
          <KeyboardArrowRight />
        )}
      </IconButton>
    </div>
  );
}

TablePaginationActions.propTypes = {
  count: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  page: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
};

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    events,
    filter,
    settings,
    eventsLoading,
    detailedLoading,
    pagination,
    detailedEvent,
    deviceDirectory,
    templateDirectory,
    onRequestEventList,
    onRequestEventItem,
    onRequestDeviceDirectory,
    onRequestTemplateDirectory,
    // onRequestTemplateList,
  } = props;

  useEffect(() => {
    // onRequestEventList(filter);
    onRequestDeviceDirectory();
    onRequestTemplateDirectory();
  }, []);

  // ***

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestEventList
  );

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  const handleChangeEventAt = (startTime, stopTime: string) => {
    handleChangeValue({
      page: 0,
      min_event_at: startTime,
      max_event_at: stopTime,
    });
  };

  const [open, setOpen] = React.useState(false);

  const handleOpenDetails = (id: number) => () => {
    onRequestEventItem(id);
    setOpen(true);
  };

  const handleCloseDetails = () => {
    setOpen(false);
  };

  const getRowStyle = (item: Event) => {
    switch (item.template.type) {
      case "event":
        return classes.event;

      case "debug":
        return classes.debug;

      case "command":
        return classes.command;

      case "error":
        return classes.error;

      default:
        return null;
    }
  };

  const getTemplateInfo = (id: number) => {
    function checkOutVersion(element, index, array) {
      if (array[index].id == id) {
        return element;
      }
    }

    const tmpl = templateDirectory.find(checkOutVersion);
    return tmpl && tmpl.title ? tmpl.title : "";
  };

  const getDeviceInfo = (id: number) => {
    function checkDevice(element, index, array) {
      if (array[index].id == id) {
        return element;
      }
    }

    const dvc = deviceDirectory.find(checkDevice);

    return dvc && dvc.title ? dvc.title : "";
  };

  return (
    <>
      <h1>События</h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="id"
                  title="ID"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.filterCell}>
                <DeviceFilter
                  value={newFilter.device_id}
                  variants={deviceDirectory}
                  onChange={(value: any) =>
                    handleChangeValue({ device_id: value })
                  }
                />
              </TableCell>
              <TableCell align="left" className={classes.filterCell}>
                <OutVersionFilter
                  value={newFilter.out_version}
                  variants={templateDirectory}
                  onChange={(value: any) =>
                    handleChangeValue({ out_version: value })
                  }
                />
              </TableCell>
              <TableCell
                align="center"
                className={classes.filterCell}
                width="30%"
              >
                <DateFilter
                  startTime={newFilter.min_event_at}
                  stopTime={newFilter.max_event_at}
                  onChange={handleChangeEventAt}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {events &&
              0 < events.length &&
              events.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="left">{item.id}</TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={getDeviceInfo(item.device_id)}
                      placement="right"
                    >
                      <Button>{item.device_id}</Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <Tooltip
                      title={getTemplateInfo(item.out_version)}
                      placement="right"
                    >
                      <Button>
                        <OutVersion value={item.out_version} />
                      </Button>
                    </Tooltip>
                  </TableCell>
                  <TableCell align="center">
                    <DateTime
                      dateFormat={settings.dateFormat}
                      timeFormat={settings.timeFormat}
                      timeZone={settings.timeZone}
                      dateTime={item.event_at}
                    />
                  </TableCell>
                  <TableCell align="right">
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      className={classes.buttonView}
                      startIcon={<VisibilityIcon />}
                      onClick={handleOpenDetails(item.id)}
                    >
                      View
                    </Button>
                  </TableCell>
                </TableRow>
              ))}

            {(!events || events.length === 0) && (
              <TableRow>
                <TableCell colSpan={6} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.PageCount || 10 < pagination.per_page) && (
        <TablePagination
          ActionsComponent={TablePaginationActions}
          component="div"
          count={pagination.TotalCount}
          page={pagination.CurrentPage}
          onPageChange={(
            event: React.MouseEvent<HTMLButtonElement> | null,
            newPage: number
          ) => handleChangeValue({ page: newPage })}
          rowsPerPage={pagination.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        <DialogTitle id="customized-dialog-title">
          Детали события <b>#{detailedEvent && detailedEvent.id}</b>
        </DialogTitle>
        <DialogContent dividers>
          {detailedLoading ? (
            <Box className={classes.progress}>
              <CircularProgress />
            </Box>
          ) : (
            <EventDetailsContainer />
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      <Backdrop className={classes.backdrop} open={eventsLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
