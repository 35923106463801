import * as types from "./ProjectTypes";

import { all, call, put, select, takeEvery } from "redux-saga/effects";
import { getProjectDirectory, getProjectList } from "./ProjectApis";
import {
  projectDirectoryRequestCompletedAction,
  projectDirectoryRequestFailedAction,
  projectsRequestCompletedAction,
  projectsRequestFailedAction,
  selectProject,
} from "./ProjectAction";

import { BaseAction } from "../Common/CommonModel";
import { State } from "../reducers";
import { creatingNotification } from "../../utils";
import { enqueueSnackbar } from "../Snackbar/SnackbarAction";

export function* watchProjectRequestStart() {
  yield takeEvery(types.GET_PROJECT_LIST_START, requestProjectList);
  yield takeEvery(types.GET_PROJECT_DIRECTORY_START, requestProjectDirectory);

  yield takeEvery(types.SELECT_PROJECT, onChangeProject);
}

const getProject = (state: State) => state.project.selected.id;

function* requestProjectList(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getProjectList, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(projectsRequestCompletedAction(resp.data));
  } catch (e) {
    yield put(projectsRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список проектов",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

function* requestProjectDirectory(action: BaseAction) {
  try {
    const prjId = yield select(getProject);
    const { resp } = yield all({
      resp: call(getProjectDirectory, prjId, action.payload),
    });

    if (resp.status !== 200) {
      throw new Error();
    }

    if (resp.data == null) {
      resp.data = [];
    }

    yield put(projectDirectoryRequestCompletedAction(resp.data));

    if (prjId == null) {
      yield put(selectProject(resp.data[0]));
    }
  } catch (e) {
    yield put(projectDirectoryRequestFailedAction());

    const errorNotification = creatingNotification(
      "Не удалось получить список проектов",
      "error"
    );
    yield put(enqueueSnackbar(errorNotification));
  }
}

// Если изменился проект в шапке => перезагружаем страницу => все необходимые стпавочники и т.д. обновятся сами
function* onChangeProject(action: BaseAction) {
  window.location.reload();
}
