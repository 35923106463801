import {
  projectDirectoryRequestStartAction,
  selectProject,
} from "../../store/Project/ProjectAction";

import { Directory } from "../../store/Common/CommonModel";
import { HeaderComponent } from "./header.component";
import { State } from "../../store/reducers";
import { connect } from "react-redux";
import { signOutAction } from "../../store/Auth/AuthAction";
import { toggleNavAction } from "../../store/Nav/NavActions";

const mapStateToProps = (state: State) => ({
  nav: state.nav,
  auth: state.auth,
  projectDirectory: state.project.directory.items,
  selectedProject: state.project.selected,
});

const mapDispatchToProps = (dispatch: any) => ({
  onSignOutAction: () => dispatch(signOutAction()),
  onToggleNav: (isOpen: boolean) => dispatch(toggleNavAction(isOpen)),
  onSelectProject: (prj: Directory) => dispatch(selectProject(prj)),
  onRequestProjectDirectory: () =>
    dispatch(projectDirectoryRequestStartAction()),
});

export const HeaderContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(HeaderComponent);
