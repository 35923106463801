import agentInstance from "../../api/agent";
import { Device } from "../Device/DeviceModel";

export const apiSignIn = (params: {}): Promise<Device[]> => {
    return new Promise<Device[]>((resolve) => {
        resolve(
            agentInstance(3).post(`auth/sign-in/`, params)
        );
    });
};
