import agentInstance from "../../api/agent";
import { Directory } from "../Common/CommonModel";
import { Project } from "./ProjectModel";

export const getProjectList = (prjId: Number, params: {}): Promise<Project[]> => {
    return new Promise<Project[]>((resolve) => {
        resolve(agentInstance(prjId).get(`projects/`, {
            params: params["filter"],
        }));
    });
};

export const getProjectDirectory = (prjId: Number, params: {}): Promise<Directory[]> => {
    return new Promise<Directory[]>((resolve) => {
        resolve(agentInstance(prjId).get(`projects/dir/`));
    });
};
