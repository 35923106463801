import {
  Backdrop,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Fab,
  Hidden,
  IconButton,
  Menu,
  MenuItem,
  Popper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Tooltip,
} from "@material-ui/core";
import { Template, TemplateFilter } from "../../store/Template/TemplateModel";
import {
  Theme,
  createStyles,
  lighten,
  makeStyles,
} from "@material-ui/core/styles";

import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import MoreVertIcon from "@material-ui/icons/MoreVert";
import { Pagination } from "../../store/Common/CommonModel";
import { default as React } from "react";
import ReactJson from "react-json-view";
import { Settings } from "../../store/Settings/SettingsModel";
import TableSortHeader from "../../components/table-sort-header";
import { TemplateCreateContainer } from "../../forms/template-create";
import { TemplateUpdateContainer } from "../../forms/template-update";
import VisibilityIcon from "@material-ui/icons/Visibility";
import useFilters from "../../hooks/useFilters";

interface Props {
  templatesLoading: boolean;
  templates: Template[];
  settings: Settings;
  pagination: Pagination;
  filter: TemplateFilter;
  isAdmin: boolean;
  onRequestTemplateList: (filter: TemplateFilter) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    table: {
      // minWidth: 700,
    },
    progress: {
      textAlign: "center",
    },
    buttonView: {
      margin: theme.spacing(1),
      marginRight: 0,
    },
    headCell: {
      paddingTop: 25,
      verticalAlign: "top",
    },
    filterCell: {
      paddingTop: 6,
      verticalAlign: "top",
    },
    backdrop: {
      zIndex: theme.zIndex.drawer + 1,
      color: "#fff",
    },
    visuallyHidden: {
      border: 0,
      clip: "rect(0 0 0 0)",
      height: 1,
      margin: -1,
      overflow: "hidden",
      padding: 0,
      position: "absolute",
      top: 20,
      width: 1,
    },
    error: {
      backgroundColor: lighten(theme.palette.error.light, 0.75),
    },
    event: {
      backgroundColor: lighten(theme.palette.info.light, 0.9),
    },
    command: {
      backgroundColor: lighten(theme.palette.warning.light, 0.9),
    },
    buttonUpload: {
      position: "fixed",
      bottom: theme.spacing(2),
      right: theme.spacing(2),
    },
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();
  const {
    templatesLoading,
    templates,
    filter,
    pagination,
    isAdmin,
    onRequestTemplateList,
  } = props;

  // ***

  const [newFilter, handleChangeOrder, handleChangeValue] = useFilters(
    filter,
    onRequestTemplateList
  );

  const handleChangePage = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    newPage: number
  ) => {
    handleChangeValue({ page: newPage });
  };

  const handleChangeRowsPerPage = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
  ) => {
    handleChangeValue({ page: 0, per_page: parseInt(event.target.value, 10) });
  };

  /* * */

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLButtonElement>(
    null
  );
  const [openActions, setOpenActions] = React.useState<null | Number>(null);

  const handleActionClick = (
    id: Number,
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    setAnchorEl(event.currentTarget);
    setOpenActions(id);
  };

  const handleActionClose = () => {
    setOpenActions(null);
    setAnchorEl(null);
  };

  // Детали шаблона

  const [open, setOpen] = React.useState(false);
  const [detailed, setDetailed] = React.useState<Template>();

  const handleOpenDetails = (item: Template) => () => {
    setDetailed(item);
    setOpen(true);
  };

  const handleCloseDetails = () => {
    setOpen(false);
    // setDetailed(null);
  };

  // Добавление шаблона

  const [openCreate, setOpenCreate] = React.useState<boolean>(false);

  const handleOpenCreate = () => {
    setOpenCreate(true);
  };

  const handleCloseCreate = () => {
    setOpenCreate(false);
  };

  // Редактирование шаблона

  const [openUpdate, setOpenUpdate] = React.useState(false);
  const [updating, setUpdating] = React.useState<Template>();

  const handleOpenUpdate = (item: Template) => () => {
    setUpdating(item);
    setOpenUpdate(true);
  };

  const handleCloseUpdate = () => {
    setOpenUpdate(false);
  };

  // ***

  const getRowStyle = (item: Template) => {
    switch (item.type) {
      case "event":
        return classes.event;

      case "command":
        return classes.command;

      case "error":
        return classes.error;

      default:
        return null;
    }
  };

  return (
    <>
      <h1>Шаблоны</h1>

      <TableContainer>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="out_version"
                  title="Версия вх./вых."
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="left" className={classes.headCell}>
                <TableSortHeader
                  id="name"
                  title="Название"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="type"
                  title="Направление"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="center" className={classes.headCell}>
                <TableSortHeader
                  id="priority"
                  title="Приоритет"
                  order={newFilter.order}
                  orderBy={newFilter.order_by}
                  onChange={handleChangeOrder}
                />
              </TableCell>
              <TableCell align="right" className={classes.headCell}>
                Действия
              </TableCell>
            </TableRow>
          </TableHead>

          <TableBody>
            {templates &&
              0 < templates.length &&
              templates.map((item) => (
                <TableRow className={getRowStyle(item)} hover key={item.id}>
                  <TableCell align="center">{item.out_version_hex}</TableCell>
                  <TableCell align="left">{item.name}</TableCell>
                  <TableCell align="center">{item.type}</TableCell>
                  <TableCell align="center">{item.priority}</TableCell>
                  <TableCell align="right">
                    <Hidden only={["xs", "sm", "md"]}>
                      <IconButton
                        color="primary"
                        onClick={handleOpenDetails(item)}
                      >
                        <Tooltip title="Детали">
                          <VisibilityIcon />
                        </Tooltip>
                      </IconButton>

                      {isAdmin && (
                        <IconButton onClick={handleOpenUpdate(item)}>
                          <Tooltip title="Редактировать">
                            <EditIcon />
                          </Tooltip>
                        </IconButton>
                      )}
                    </Hidden>

                    <Hidden only={["lg", "xl"]}>
                      <Popper
                        id={"actions-" + item.id}
                        open={openActions === item.id}
                        anchorEl={anchorEl}
                        placement="bottom"
                      >
                        <Menu
                          id="simple-menu"
                          anchorEl={anchorEl}
                          keepMounted
                          open={true}
                          onClick={handleActionClose}
                          onClose={handleActionClose}
                        >
                          <MenuItem
                            color="primary"
                            onClick={handleOpenDetails(item)}
                          >
                            Детали
                          </MenuItem>

                          {isAdmin && (
                            <MenuItem onClick={handleOpenUpdate(item)}>
                              Редактировать
                            </MenuItem>
                          )}
                        </Menu>
                      </Popper>

                      <IconButton
                        id={"show-actions-" + item.id}
                        aria-haspopup="true"
                        onClick={(e) => handleActionClick(item.id, e)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                    </Hidden>
                  </TableCell>
                </TableRow>
              ))}

            {(!templates || templates.length === 0) && (
              <TableRow>
                <TableCell colSpan={5} align="center">
                  Записей нет
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>

      {(1 < pagination.PageCount || 10 < pagination.per_page) && (
        <TablePagination
          component="div"
          count={pagination.TotalCount}
          page={pagination.CurrentPage}
          onPageChange={handleChangePage}
          rowsPerPage={pagination.per_page}
          onRowsPerPageChange={handleChangeRowsPerPage}
          labelRowsPerPage="Per page:"
        />
      )}

      {isAdmin && (
        <Fab
          color="primary"
          aria-label="add"
          className={classes.buttonUpload}
          onClick={handleOpenCreate}
        >
          <AddIcon />
        </Fab>
      )}

      <Dialog
        scroll="paper"
        onClose={handleCloseDetails}
        aria-labelledby="customized-dialog-title"
        open={open}
        maxWidth="md"
        fullWidth={true}
      >
        {detailed && (
          <>
            <DialogTitle id="customized-dialog-title">
              Шаблон: <b>{detailed.name}</b>
            </DialogTitle>
            <DialogContent dividers>
              <ul>
                <li>
                  <b>ID:</b> {detailed.id}
                </li>
                <li>
                  <b>Версия вх./вых.:</b> {detailed.out_version_hex}
                </li>
                <li>
                  <b>Описание:</b> {detailed.comment}
                </li>
                <li>
                  <b>Направление:</b> {detailed.type}
                </li>
                <li>
                  <b>Приоритет:</b> {detailed.priority}
                </li>
                <li>
                  <b>Время жизни:</b> {detailed.ttl} дней
                </li>
                <li>
                  <b>Данные:</b>
                  <ReactJson
                    style={{
                      padding: 10,
                      border: "1px solid rgba(0, 0, 0, 0.12)",
                      borderRadius: 3,
                    }}
                    // theme="monokai"
                    src={detailed.fields}
                    name={null}
                    displayObjectSize={false}
                    displayDataTypes={false}
                    enableClipboard={false}
                  />
                </li>
              </ul>
            </DialogContent>
          </>
        )}
        <DialogActions>
          <Button onClick={handleCloseDetails} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>

      {isAdmin && (
        <Dialog
          scroll="paper"
          onClose={handleCloseUpdate}
          aria-labelledby="customized-dialog-title"
          open={openUpdate}
          maxWidth="md"
          fullWidth={true}
        >
          <TemplateUpdateContainer
            handleClose={handleCloseUpdate}
            template={updating}
          />
        </Dialog>
      )}

      {isAdmin && (
        <Dialog
          scroll="paper"
          onClose={handleCloseCreate}
          aria-labelledby="customized-dialog-title"
          open={openCreate}
          maxWidth="md"
          fullWidth={true}
        >
          <TemplateCreateContainer handleClose={handleCloseCreate} />
        </Dialog>
      )}

      <Backdrop className={classes.backdrop} open={templatesLoading}>
        <CircularProgress color="inherit" />
      </Backdrop>
    </>
  );
};
