import React from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import EmptyLayout from "./layouts/empty";
import MainLayout from "./layouts/main";
import { PageContainer as CommandsPage } from "./pages/commands/page.container";
import { PageContainer as DevicesPage } from "./pages/devices/page.container";
import { PageContainer as Error404Page } from "./pages/error404/page.container";
import { PageContainer as EventsPage } from "./pages/events/page.container";
import { PageContainer as FirmwaresPage } from "./pages/firmwares/page.container";
import { PageContainer as HomePage } from "./pages/home/page.container";
import { PageContainer as ProjectsPage } from "./pages/projects/page.container";
import { PageContainer as SignInPage } from "./pages/sign-in/page.container";
import { PageContainer as TemplatesPage } from "./pages/templates/page.container";
import { PageContainer as UsersPage } from "./pages/users/page.container";

const MainRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
      )}
    />
  );
};

const EmptyRoute = ({ component: Component, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) => (
        <EmptyLayout>
          <Component {...props} />
        </EmptyLayout>
      )}
    />
  );
};

export default () => (
  <Router>
    <Switch>
      <MainRoute path="/" exact component={HomePage} />

      <MainRoute path="/projects" component={ProjectsPage} />

      <MainRoute path="/devices" component={DevicesPage} />
      <MainRoute path="/events" component={EventsPage} />
      <MainRoute path="/commands" component={CommandsPage} />
      <MainRoute path="/firmwares" component={FirmwaresPage} />
      <MainRoute path="/templates" component={TemplatesPage} />
      <MainRoute path="/users" component={UsersPage} />

      <EmptyRoute path="/auth/sign-in" component={SignInPage} />

      <EmptyRoute component={Error404Page} />
    </Switch>
  </Router>
);
