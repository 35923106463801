import {
  Accordion,
  AccordionActions,
  AccordionDetails,
  AccordionSummary,
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  LinearProgress,
  TextField,
  Typography,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import Autocomplete from "@material-ui/lab/Autocomplete";
import React from "react";
import { Directory } from "../../../store/Common/CommonModel";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: "100%",
    },
    command: {
      borderSize: 3,
      marginBottom: 15,
    },
    runButton: {
      // marginRight: 10,
    },
    commandHead: {
      backgroundColor: "rgba(0, 0, 0, .1)",
    },
    commandTitle: {
      fontSize: theme.typography.pxToRem(16),
      lineHeight: theme.typography.pxToRem(30),
    },
    commandContent: {
      display: "block",
      borderTop: "1px solid rgba(0, 0, 0, 0.12);",
      padding: "5px 16px",
    },
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    optionGroup: {
      background: "white",
    },
    chips: {},
    chip: {},
  })
);

export function CommandDefault(props) {
  const classes = useStyles();
  const {
    id,
    handleChange,
    commandRunning,
    handleRunCommand,
    templateDirLoading,
    templateDirectory,
    deviceDirectory,
    deviceDirLoading,
  } = props;

  const [outVersion, setOutVersion] = React.useState<Directory>(null);
  const [formDevice, setFormDevice] = React.useState<Directory>(null);
  const [params, setParams] = React.useState("{}");

  const handleChangeOutVersion = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    template: Directory
  ) => {
    setOutVersion(template);
  };

  const handleChangeDevice = (
    event: React.MouseEvent<HTMLButtonElement> | null,
    device: Directory
  ) => {
    setFormDevice(device);
  };

  const handleChangeParams = (event: any) => {
    setParams(event.target.value);
  };

  const handleReset = (event: any) => {
    setOutVersion(null);
    setFormDevice(null);
    setParams("{}");
  };

  const handleSubmit = (event: any) => {
    event.preventDefault();
    handleRunCommand(outVersion.id, formDevice.id, params);
  };

  return (
    <Accordion
      className={classes.command}
      expanded={true}
      TransitionProps={{ unmountOnExit: true }}
      onChange={handleChange(id)}
    >
      <AccordionSummary
        className={classes.commandHead}
        expandIcon={<ExpandMoreIcon />}
        aria-label="Expand"
        id={"command-" + id}
      >
        <Typography>Default command</Typography>
      </AccordionSummary>

      <AccordionDetails className={classes.commandContent}>
        <LinearProgress color="secondary" hidden={!commandRunning} />
        <Box component="p">
          Универсальный метод для отправки ЛЮБОЙ команды на контроллер.
        </Box>
        <Box component="p">
          <b>Внимание!</b> Накосячить очень просто! По возможности, используйте
          команды ниже.
        </Box>

        <FormControl className={classes.formControl}>
          <Autocomplete
            id="device-box"
            options={templateDirectory.filter((item: Directory) => {
              return item.group === "command";
            })}
            getOptionLabel={(option: Directory) => option.title}
            getOptionSelected={(option: Directory, value: Directory) => {
              return option.id === value.id;
            }}
            disabled={templateDirLoading}
            limitTags={1}
            value={outVersion}
            onChange={handleChangeOutVersion}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Версия выхода"
                variant="standard"
                placeholder="Версия выхода"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {templateDirLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />

          {/* <InputLabel id="run-command-outversion-label">
            Версия выхода
          </InputLabel>
          <Select
            labelId="run-command-outversion-label"
            id="run-command-outversion"
            value={outVersion}
            onChange={handleChangeOutVersion}
            disabled={templatesLoading || templates.length < 1}
          >
            <ListSubheader className={classes.optionGroup}>
              Команды
            </ListSubheader>
            {templates &&
              templates
                .filter((item: Directory) => {
                  return item.group === "command";
                })
                .map((item: Directory) => (
                  <MenuItem
                    value={item.id}
                    key={"command-outversion-" + item.id}
                  >
                    {item.title}
                  </MenuItem>
                ))}
          </Select> */}
        </FormControl>

        <FormControl className={classes.formControl}>
          <Autocomplete
            id="device-box"
            options={deviceDirectory}
            getOptionLabel={(option: Directory) =>
              "#" + option.id + " - " + option.title
            }
            getOptionSelected={(option: Directory, value: Directory) => {
              return option.id === value.id;
            }}
            disabled={deviceDirLoading}
            limitTags={1}
            value={formDevice}
            onChange={handleChangeDevice}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Контроллеры"
                variant="standard"
                placeholder="Контроллеры"
                InputProps={{
                  ...params.InputProps,
                  endAdornment: (
                    <React.Fragment>
                      {deviceDirLoading ? (
                        <CircularProgress color="inherit" size={20} />
                      ) : null}
                      {params.InputProps.endAdornment}
                    </React.Fragment>
                  ),
                }}
              />
            )}
          />
        </FormControl>

        <FormControl className={classes.formControl}>
          <TextField
            id="params"
            label="Параметры команды"
            margin="dense"
            multiline
            rows={5}
            fullWidth
            value={params}
            onChange={handleChangeParams}
          />
        </FormControl>
      </AccordionDetails>

      <Divider />

      <AccordionActions>
        {" "}
        <Button onClick={handleReset} color="primary">
          Cancel
        </Button>
        <Button
          onClick={handleSubmit}
          variant="contained"
          color="primary"
          id="run-command"
          disabled={
            deviceDirLoading ||
            deviceDirectory.length < 1 ||
            templateDirLoading ||
            templateDirectory.length < 1
          }
        >
          Run
        </Button>
      </AccordionActions>
    </Accordion>
  );
}
