import {
  Button,
  CircularProgress,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  Grid,
  TextField,
} from "@material-ui/core";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";
import { TemplateCreateForm } from "../../store/Template/TemplateModel";

interface Props {
  templateCreating: boolean;
  onRequestTemplateCreate: (form: TemplateCreateForm) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    formControl: {
      margin: theme.spacing(1),
      marginLeft: 0,
      marginRight: 0,
      width: "100%",
    },
    chips: {
      display: "flex",
      flexWrap: "wrap",
    },
    chip: {
      margin: 2,
    },
  })
);

export const TemplateCreateComponent = (props: any) => {
  const classes = useStyles();
  const { template, handleClose, templateCreating, onRequestTemplateCreate } =
    props;

  const createForm = {
    out_version: "0x",
    type: "",
    priority: 0,
    ttl: 0,
    name: "",
    comment: "",
    fields: "",
  };

  const [fields, setFields] = React.useState<TemplateCreateForm>(createForm);

  const handleChangeField =
    (prop: keyof TemplateCreateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: event.target.value });
    };

  const handleChangeNumberField =
    (prop: keyof TemplateCreateForm) =>
    (event: React.ChangeEvent<HTMLInputElement>) => {
      setFields({ ...fields, [prop]: Number(event.target.value) });
    };

  // ***

  const handleStartUpdate = () => {
    onRequestTemplateCreate(fields);
    handleClose();
  };

  return (
    <>
      <DialogTitle>Создание шаблона</DialogTitle>
      <DialogContent dividers>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.name}
                label="Название"
                onChange={handleChangeField("name")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.type}
                label="Тип"
                onChange={handleChangeField("type")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.out_version}
                label="Версия Вх./Выхода (в hex)"
                onChange={handleChangeField("out_version")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.comment}
                label="Комментарий"
                onChange={handleChangeField("comment")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.priority}
                label="Приоритет"
                type="number"
                onChange={handleChangeNumberField("priority")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.ttl}
                label="Время жизни (в днях)"
                type="number"
                onChange={handleChangeNumberField("ttl")}
              />
            </FormControl>
          </Grid>

          <Grid item xs={12} sm={12}>
            <FormControl className={classes.formControl}>
              <TextField
                value={fields.fields}
                label="Схема"
                multiline
                minRows={10}
                maxRows={10}
                onChange={handleChangeField("fields")}
              />
            </FormControl>
          </Grid>
        </Grid>
      </DialogContent>
      <DialogActions>
        {!templateCreating && (
          <>
            <Button onClick={handleClose} color="primary">
              Cancel
            </Button>
            <Button
              onClick={handleStartUpdate}
              variant="contained"
              color="primary"
            >
              Сохранить
            </Button>
          </>
        )}

        {templateCreating && <CircularProgress />}
      </DialogActions>
    </>
  );
};
