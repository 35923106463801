import agentInstance from "../../api/agent";
import { File, FileRequest } from "./FileModel";

export const postCreateFileRequest = (prjId: Number, params: {}): Promise<FileRequest[]> => {
    return new Promise<FileRequest[]>((resolve) => {
        resolve(
            agentInstance(prjId).post(`files/requests/`, {
                device_id: params["deviceId"],
                mask: params["mask"],
            })
        );
    });
};

export const getFileRequestList = (prjId: Number, params: {}): Promise<FileRequest[]> => {
    return new Promise<FileRequest[]>((resolve) => {
        resolve(
            agentInstance(prjId).get(`files/requests/`, {
                params: params["filter"],
            })
        );
    });
};

export const getFileList = (prjId: Number, params: {}): Promise<FileRequest[]> => {
    return new Promise<FileRequest[]>((resolve) => {
        resolve(
            agentInstance(prjId).get(`files/requests/`+params["filter"]["request_id"]+`/`, {
                params: params["filter"],
            })
        );
    });
};

export const postDownloadFile = (prjId: Number, params: {}): Promise<File[]> => {
    return new Promise<File[]>((resolve) => {
        resolve(
            agentInstance(prjId).post(`files/`+params["fileId"]+"/", {
                did: params["deviceId"],
            })
        );
    });
};

