import * as types from "./UserTypes";

import { BaseAction, Pagination } from "../Common/CommonModel";
import { User, UserFilter } from "./UserModel";

export type UserState = {
  list: {
    loading: boolean;
    items: User[];
    pagination: Pagination;
  };
  creating: boolean;
  updating: boolean;
  filter: UserFilter;
};

const defaultUserPagination = {
  CurrentPage: 0,
  PageCount: 0,
  per_page: 25,
  TotalCount: 0,
};

export const defaultUserState: UserState = {
  list: {
    loading: false,
    items: [],
    pagination: defaultUserPagination,
  },
  creating: false,
  updating: false,
  filter: {
    page: 0,
    per_page: defaultUserPagination.per_page,
    order_by: "id",
    order: "asc",
  },
};

export const userReducer = (
  state: UserState = defaultUserState,
  action: BaseAction
) => {
  switch (action.type) {
    case types.GET_USER_LIST_START:
      return {
        ...state,
        list: {
          ...state.list,
          loading: true,
        },
        filter: action.payload.filter,
      };

    case types.GET_USER_LIST_COMPLETED:
      return {
        ...state,
        list: {
          loading: false,
          items: action.payload.items,
          pagination: action.payload.pagination,
        },
      };

    case types.GET_USER_LIST_FAILED:
      return {
        ...state,
        list: {
          loading: false,
          items: [],
          pagination: defaultUserPagination,
        },
      };

    // ***

    case types.CREATE_USER_START:
      return {
        ...state,
        creating: true,
      };

    case types.CREATE_USER_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.CREATE_USER_FAILED:
      return {
        ...state,
        creating: false,
      };

    // ***

    case types.UPDATE_USER_START:
      return {
        ...state,
        creating: true,
      };

    case types.UPDATE_USER_COMPLETED:
      return {
        ...state,
        creating: false,
      };

    case types.UPDATE_USER_FAILED:
      return {
        ...state,
        creating: false,
      };
  }

  return state;
};
