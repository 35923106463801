import { applyMiddleware, compose, createStore } from "redux";
import { load, save } from "redux-localstorage-simple";

import createSagaMiddleware from "redux-saga";
import { defaultAuthState } from "./Auth/AuthReducer";
import { defaultDeviceState } from "./Device/DeviceReducer";
import { defaultEventState } from "./Event/EventReducer";
import { defaultFirmwareState } from "./Firmware/FirmwareReducer";
import { defaultProjectState } from "./Project/ProjectReducer";
import { defaultTemplateState } from "./Template/TemplateReducer";
import { rootReducer } from "./reducers";
import { rootSaga } from "./sagas";

const sagaMiddleware = createSagaMiddleware();

const composeEnhancer =
  (process.env.NODE_ENV !== "production" &&
    window["__REDUX_DEVTOOLS_EXTENSION_COMPOSE__"]) ||
  compose;

export const store = createStore(
  rootReducer,
  load({
    namespace: "axiot",
    states: [
      "auth.isAuthenticated",
      "auth.accessToken",
      "project.selected",
      "project.filter.order",
      "project.filter.order_by",
      "device.filter.show_blocked",
      "device.filter.order",
      "device.filter.order_by",
      "device.filter.per_page",
      "event.filter.order",
      "event.filter.order_by",
      "event.filter.per_page",
      "firmware.filter.order",
      "firmware.filter.order_by",
      "firmware.filter.per_page",
      "template.filter.order",
      "template.filter.order_by",
      "template.filter.per_page",
      "settings",
    ],
    preloadedState: {
      auth: defaultAuthState,
      device: defaultDeviceState,
      event: defaultEventState,
      template: defaultTemplateState,
      firmware: defaultFirmwareState,
      project: defaultProjectState,
    },
  }),
  composeEnhancer(
    applyMiddleware(
      save({
        namespace: "axiot",
        states: [
          "auth.isAuthenticated",
          "auth.accessToken",
          "project.selected",
          "project.filter.order",
          "project.filter.order_by",
          "device.filter.show_blocked",
          "device.filter.order",
          "device.filter.order_by",
          "device.filter.per_page",
          "event.filter.order",
          "event.filter.order_by",
          "event.filter.per_page",
          "firmware.filter.order",
          "firmware.filter.order_by",
          "firmware.filter.per_page",
          "template.filter.order",
          "template.filter.order_by",
          "template.filter.per_page",
          "settings",
        ],
        ignoreStates: ["snackbar", "nav"],
      }),
      sagaMiddleware
    )
  )
);

sagaMiddleware.run(rootSaga);
