import { Grid } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import React from "react";
import { rfc3339 } from "../../utils";

export const DateFilter = (props: any) => {
  const { startTime, stopTime, onChange } = props;
  const [startDate, setStartDateChange] = React.useState(startTime);
  const [stopDate, setStopDateChange] = React.useState(stopTime);

  const handleStartDateChange = (date: Date) => {
    var newDate = rfc3339(date, true);
    setStartDateChange(newDate);
    handleChange(newDate, stopDate);
  };

  const handleStopDateChange = (date: Date) => {
    var newDate = rfc3339(date, true);
    setStopDateChange(newDate);
    handleChange(startDate, newDate);
  };

  const handleChange = (fStartDate, fStopDate) => {
    if (fStartDate == startDate && fStopDate == stopDate) return;
    onChange(fStartDate, fStopDate);
  };

  return (
    <>
      <Grid container spacing={3}>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            label="Начало периода"
            clearable
            strictCompareDates
            value={startDate}
            emptyLabel="Выберите дату"
            onChange={handleStartDateChange}
            maxDate={new Date()}
            format="dd.MM.yyyy"
            // onAccept={handleStartDateAccept}
            // onClose={handleStartDateClose}
          />
        </Grid>
        <Grid item xs={6}>
          <DatePicker
            autoOk
            label="Конец периода"
            clearable
            strictCompareDates
            value={stopDate}
            emptyLabel="Выберите дату"
            onChange={handleStopDateChange}
            minDate={startDate}
            maxDate={new Date()}
            format="dd.MM.yyyy"
            // onAccept={setStopDateChange}
            // onClose={handleChange}
          />
        </Grid>
      </Grid>
    </>
  );
};
