import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import React from "react";

interface Props {}
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    chart: {
      background: "#eeeeee",
      border: "1px solid red",
      width: "100%",
      height: 200,
    },
    title: {},
  })
);

export const PageComponent: React.FunctionComponent<Props> = (props) => {
  const classes = useStyles();

  return (
    <>
      <h1 className={classes.title}>Главная</h1>
    </>
  );
};
